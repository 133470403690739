import { combineReducers, Reducer } from 'redux';
//cli_import_section
import AccountingReducer from './reducers/AccountingReducer';
import AccountReducer from './reducers/AccountReducer';
import InternationalisationReducer from './reducers/InternationalisationReducer';
import CitiesReducer from './reducers/CitiesReducer';
import GeneralSettingsReducer from './reducers/GeneralSettingsReducer';

import { ApplicationState } from './types';
import UsersReducer from './reducers/UsersReducer';

const rootReducer: Reducer<ApplicationState> =
  combineReducers<ApplicationState>({
    //cli_reducer_section
    accounting: AccountingReducer,
    account: AccountReducer,
    users: UsersReducer,
    i18n: InternationalisationReducer,
    general_setting: GeneralSettingsReducer,
    cities: CitiesReducer
  });

export default rootReducer;
