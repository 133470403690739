import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { logout } from 'store/actions';
import { ApplicationState } from 'store/types';
import { useSocket } from 'use-socketio';
import { UpdateAccount } from './Component';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

interface AccountProps {
  // onChangePassword: Function;
}

function Account(props: AccountProps) {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state: ApplicationState) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [dialogAccountOpen, setDialogAccountOpen] = useState<boolean>(false);
  const account = useSelector((state: ApplicationState) => state.account);
  const { socket } = useSocket();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/auth/login');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}>
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${user?.surname}`}
          </Typography>
        </Hidden>
      </Box>
      <UpdateAccount open={dialogAccountOpen} setOpen={setDialogAccountOpen} />
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}>
        <MenuItem
          onClick={() => {
            handleClose();
            setDialogAccountOpen(true);
          }}>
          Editer mon profil
        </MenuItem>
        <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
