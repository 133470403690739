import React, { FunctionComponent, ReactNode } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Check, Check as CheckIcon, Close as CloseIcon} from '@material-ui/icons';
import styles from './DialogConfirm.module.scss';
import {useTranslation} from "react-i18next";

interface DialogConfirmInterface {
  open: boolean;
  onConfirmDialogClose?: Function;
  text?: ReactNode | string;
  loading?: boolean;
  title?: string;
  onYesClick: Function;
}

const ConfirmationDialog: FunctionComponent<DialogConfirmInterface> = ({
  open,
  onConfirmDialogClose = () => {},
  text = '',
  loading = false,
  title = 'Confirmation',
  onYesClick = () => {}
}) => {
  const {t} = useTranslation();
  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={open}
      onClose={() => onConfirmDialogClose()}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box position={'relative'}>
          {loading && (
            <Box
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <CircularProgress size={24} />
            </Box>
          )}
          <Button
            disabled={loading}
            color={'primary'}
            startIcon={<Check/>}
            onClick={() => onYesClick()}
            style={{ marginRight: 10 }}
            variant="contained">
            {t('dialog_action_confirm')}
          </Button>
        </Box>
        <Button
          onClick={() => onConfirmDialogClose()}
          variant="outlined"
          color={'primary'}
          startIcon={<CloseIcon />}
          disabled={loading}>
          {t('dialog_action_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
