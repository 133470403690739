import {ThunkAction} from 'redux-thunk';
import {ApplicationAction, ApplicationState} from 'store/types';
import {loginSuccess} from 'store/actions';
import {AuthService} from 'services/api';
import {loginError, loginRequest} from 'store/actions/AccountActions';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const getUserInfo = (): Effect => async (dispatch, getState) => {
    const account = getState().account
    return AuthService.get_userInfo()
        .then(async (res: any) => {
            if (res.status === 200) {
                let {user} = await res.json();
                return dispatch(loginSuccess({...account.user, ...user}, account.token || ''));
            }
        })
        .catch((err: any) => {
            console.log(err);
        });
};

export const updateUserInfoEffect = (
    payload: any,
    setLoading: (loading: boolean) => void,
    enqueueSnackbar: Function
)
    : Effect => async (dispatch, getState) => {
    const {account, i18n: {t = (key: string) => key}} = getState();
    setLoading(true);
    return AuthService.update_userInfo(payload)
        .then(async (res: any) => {
            const status = res.status === 200 || res.status === 201;
            let {user, message} = await res.json();
            enqueueSnackbar(message, {
                variant: status ? 'success' : 'warning'
            });
            if (status) {
                return dispatch(loginSuccess({...account.user, ...user}, account.token || ''));
            }
        })
        .catch((err: any) => {
            enqueueSnackbar(t('shared.internet_connexion_error'), {
                variant: 'error'
            });
        })
        .finally(() => setLoading(false))
};


export const updatePasswordEffect = (
    payload: any,
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    enqueueSnackbar: Function
)
    : Effect => async (dispatch, getState) => {
    const {i18n: {t = (key: string) => key}} = getState();
    setLoading(true);
    return AuthService.update_password(payload)
        .then(async (res: any) => {
            const status = res.status === 200 || res.status === 201;
            let {message} = await res.json();
            if (status) onSuccess()
            enqueueSnackbar(message, {
                variant: status ? 'success' : 'warning'
            });
        })
        .catch((err: any) => {
            enqueueSnackbar(t('shared.internet_connexion_error'), {
                variant: 'error'
            });
        })
        .finally(() => setLoading(false))
};


export const loginEffect = (
    login: string,
    password: string
): Effect => async (dispatch, getState) => {
    const {
        i18n: {t = (key: string) => key}
    } = getState();
    dispatch(loginRequest());
    return AuthService.login({login, password})
        .then(async (res: any) => {
            if (res.status === 200) {
                let {
                    user,
                    token
                } = await res.json();
                return dispatch(loginSuccess(user, token));
            } else {
                let {message} = await res.json();
                dispatch(loginError(message));
            }
        })
        .catch((err: any) => {
            return dispatch(loginError(t('shared.internet_connexion_error')));
        });
};

