import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  InputAdornment,
  StandardTextFieldProps,
  SvgIconProps,
  Typography
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: (props: StandardTextFieldProps) =>
      props.margin === 'none' ? 0 : 6,
    marginBottom: (props: StandardTextFieldProps) =>
      props.margin === 'none' ? 0 : 16,
    borderRadius: 5
  },
  textFieldSuffixContainer: {
    display: 'flex',
    alignItems: 'stretch'
  },
  textFieldSuffix: {
    flexGrow: 1,
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px 0 0 5px'
    }
  },
  textFieldPrefix: {
    flexGrow: 1,
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 5px 5px 0'
    }
  },
  overflowLabel: {
    backgroundColor: '#DDDDDD',
    display: 'flex',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey['700'],
    padding: theme.spacing(0, 2)
  },
  suffix: {
    borderRadius: '0 5px 5px 0'
  },
  prefix: {
    borderRadius: '5px 0 0 5px'
  }
}));

export interface InputTextProps extends StandardTextFieldProps {
  labelPrefix?: string;
  labelSuffix?: string;
  startIcon?: React.ReactElement<SvgIconProps>;
  endIcon?: React.ReactElement<SvgIconProps>;
}

const InputText = (props: InputTextProps) => {
  const {
    label,
    labelPrefix,
    labelSuffix,
    helperText,
    startIcon = <div />,
    endIcon = <div />,
    ...rest
  } = props;
  const styles = useStyles(props);
  const { t } = useTranslation();
  return (
    <div>
      <div
        className={clsx(
          (!!labelSuffix || !!labelPrefix) && styles.textFieldSuffixContainer
        )}>
        {!!labelPrefix && (
          <Typography
            variant={'h5'}
            className={clsx(styles.prefix, styles.overflowLabel, styles.root)}>
            {labelPrefix}
          </Typography>
        )}
        <TextField
          label={label && t(`${label}`)}
          helperText={!!helperText && t(`${helperText}`)}
          classes={{
            root: clsx(
              styles.root,
              !!labelSuffix && styles.textFieldSuffix,
              !!labelPrefix && styles.textFieldPrefix
            )
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            )
          }}
          {...rest}
          variant={'outlined'}
        />
        {!!labelSuffix && (
          <Typography
            variant={'h5'}
            className={clsx(styles.suffix, styles.overflowLabel, styles.root)}>
            {labelSuffix}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default InputText;
