import { Action } from 'redux';
import { Asset, SimpleCity, SimpleHousing } from './CitiesTypes';
import { MultiSelectOption } from '../../components/FormBuilder';
import { User } from './AccountTypes';

//cli_export_interface_section
export const MARK_HOUSINGS_AS_PAID = 'MARK_HOUSINGS_AS_PAID';

export interface MarkHousingsAsPaidAction extends Action {
  type: typeof MARK_HOUSINGS_AS_PAID;
  data: MarkHousingsAsPaidActionParams;
}

export interface AccountingRange {
  start_date: string | Date;
  end_date: string | Date;
  housing_id?: number;
  creator?: MultiSelectOption;
  creator_id?: number | string;
}

export interface MarkHousingsAsPaidActionParams {
  housing_ids: number[];
}

export const SET_ACCOUNTING_LIST = 'SET_ACCOUNTING_LIST';

export interface SetAccountingListAction extends Action {
  type: typeof SET_ACCOUNTING_LIST;
  data: SetAccountingListActionParams;
}

export interface SetAccountingListActionParams {
  housings: AccommodationHousing[];
  range?: AccountingRange;
}

interface City extends SimpleCity {
  creator: User;
}

export interface AccommodationHousing extends SimpleHousing {
  city: City;
  assets?: Asset[];
}

export interface AccountingState {
  housings: AccommodationHousing[];
  housings_range?: AccountingRange;
}
