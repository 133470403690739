import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { MultiSelectOption } from '../../FormBuilder';

const useStyles = makeStyles((theme) => ({}));

export interface AsyncAutocompleteProps {
  value?: any;
  onChange?: (value: any) => void;
  mapResultRequestToAutocompleteData: (data: any) => MultiSelectOption[];
  httpServiceProvider: (keyword: string) => any;
  noOptionText?: string;
  loadingText?: string;
  label?: string;
  multiple?: boolean;
  keywordEmptyPlaceholder?: string;
}

export default function (props: AsyncAutocompleteProps) {
  const classes = useStyles();
  const [autoCompleteData, setAutoCompleteData] = useState<MultiSelectOption[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [timerId, setTimerId] = useState<any | undefined>();

  useEffect(() => {
    if (keyword.length > 0) {
      if (timerId) clearTimeout(timerId);
      setTimerId(setTimeout(fetchResidences, 500));
    }
  }, [keyword]);

  const fetchResidences = () => {
    setLoading(true);
    props
      .httpServiceProvider(keyword)
      .then(async (response: any) => {
        if (response.status === 200 || response.status === 201) {
          const result = await response.json();
          setAutoCompleteData(props.mapResultRequestToAutocompleteData(result));
          setOpen(true);
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(t('shared.internet_connexion_error'), {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div>
      <Autocomplete
        id="asynchronous-demo"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event: any, value: any) =>
          props.onChange && props.onChange(value)
        }
        value={props.value}
        multiple={props.multiple}
        loadingText={props.loadingText && t(props.loadingText)}
        noOptionsText={t(
          loading
            ? props.loadingText || 'auto_complete_loading'
            : keyword.length === 0
            ? props.keywordEmptyPlaceholder || 'auto_complete_keyword_empty'
            : props.noOptionText || 'auto_complete_empty'
        )}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.label}` || ''}
        options={autoCompleteData}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label && t(`${props.label}`)}
            onChange={(event) => {
              event.persist();
              setKeyword(event.target.value);
            }}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </div>
  );
}
