import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Marker } from 'google-maps-react';
import { AsyncAutocomplete, MapContainer } from 'components';
import { GoogleService } from '../../services/api';
import { IMapProps } from 'google-maps-react';
import { MapConfig } from '../../services/api/GoogleService';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minHeight: 'calc(100vh - 225px)',
    display: 'flex',
    flexDirection: 'column'
  },
  map: {
    flexGrow: 1,
    position: 'relative'
  }
}));

interface Location {
  lat: number;
  lng: number;
}

interface GoogleLocationProvider {
  name: string;
  value?: Location;
  onChange: (value: Location | undefined) => void;
  label: string;
  onTouch?: () => void;
}

export default function GoogleLocationProvider(props: GoogleLocationProvider) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [mapConfig, setMapConfig] = useState<MapConfig | undefined>();
  const [marker, setMarker] = useState<Location | undefined>(props.value);
  const onClose = () => setOpen(false);
  const google = mapConfig?.mapProps?.google;
  return (
    <div>
      <Button
        color={props.value ? 'primary' : 'default'}
        variant={'contained'}
        onClick={() => {
          setOpen(true);
          props.onTouch && props.onTouch();
        }}
        fullWidth
        size={'large'}
        endIcon={<Edit />}>
        {props.value ? `${props.value.lng} ${props.value.lat}` : t(props.label)}
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'md'}
        fullScreen={matches}>
        <DialogTitle>
          {mapConfig && (
            <AsyncAutocomplete
              label={'find_location'}
              onChange={(position) => {
                if (mapConfig && mapConfig.mapProps && position) {
                  const request = {
                    placeId: position.id,
                    fields: ['name', 'rating', 'geometry']
                  };
                  const { map } = mapConfig;
                  const { google } = mapConfig.mapProps;
                  const service = new google.maps.places.PlacesService(map);
                  service.getDetails(request, (place: any, status: any) => {
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                      map.setCenter(place.geometry.location);
                      mapConfig?.map.setZoom(14);
                    }
                  });
                }
              }}
              httpServiceProvider={(keyword) =>
                GoogleService.fetch_place(keyword, mapConfig)
              }
              mapResultRequestToAutocompleteData={(data) => {
                return data.map((i: any) => ({
                  label: i.description,
                  id: i.place_id
                }));
              }}
            />
          )}
          {/*{t(props.label)}*/}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.map}>
            <MapContainer
              onClick={(mapProps, map, clickEvent) => {
                setMarker({
                  lat: clickEvent.latLng.lat(),
                  lng: clickEvent.latLng.lng()
                });
              }}
              onTilesloaded={(mapProps, map) => {
                setMapConfig({
                  map,
                  mapProps
                });
              }}>
              {marker && (
                <Marker
                  //@ts-ignore
                  draggable={true}
                  onDragend={(mapProps: any, map: any, clickEvent: any) => {
                    setMarker({
                      lat: clickEvent.latLng.lat(),
                      lng: clickEvent.latLng.lng()
                    });
                  }}
                  //@ts-ignore
                  icon={{
                    url: '/images/ic_map.png',
                    anchor: google && new google.maps.Point(16, 16),
                    scaledSize: google && new google.maps.Size(32, 32)
                  }}
                  //@ts-ignore
                  name={props.name}
                  //@ts-ignore
                  title={t(props.label)}
                  //@ts-ignore
                  position={marker}
                />
              )}
            </MapContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={'primary'} variant={'outlined'}>
            {t('settings_cancel_action')}
          </Button>
          <Button
            onClick={() => {
              props.onChange(marker);
              onClose();
            }}
            color={'primary'}
            variant={'contained'}>
            {t('settings_validate_action')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
