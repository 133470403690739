import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import CustomMenuItem from './CusttomMenuItem';
import config from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

interface Props {
  onSelectOption: any;
}

export default function (props: Props) {
  const { onSelectOption } = props;
  const classes = useStyles();
  const names = ['Elève/étudiant', 'Etablissement', 'Recruteur'];
  const icons = [
    {
      src: 'menu_item_student',
      PROD: 'https://www.millameet.com/',
      DEV: 'https://dev.millameet.com/'
    },
    {
      src: 'menu_item_establishment',
      PROD: 'http://school.millameet.com/',
      DEV: 'http://school.dev.millameet.com/'
    },
    {
      src: 'menu_item_recruiter',
      PROD: 'http://recruiter.millameet.com/',
      DEV: 'http://recruiter.dev.millameet.com/ '
    }
  ];

  const { env } = config;

  return (
    <Box display={'flex'} className={classes.root}>
      {[0, 1, 2].map((i, id) => (
        <CustomMenuItem
          key={i}
          active={id === 1}
          onClick={() => window.location.replace(`${(icons as any)[i][env]}`)}
          icon={`/images/${icons[id].src}.svg`}
          title={names[id]}
        />
      ))}
    </Box>
  );
}
