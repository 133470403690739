import {User} from 'store/types';

interface AuthResults {
    password: string;
    Authorization: string;
    message: string;
    data: {
        user: User;
    };
}

export const connected_user: User = {
    id: 1,
    name: 'Admin',
    surname: 'admin',
    role: 'ADMIN_ROLE',
    email: null,
    phone: '699999999',
    login: 'admin@lespioles.com',
    email_token: null,
    firebase_token: null,
    verified: true,
    lock: false,
    created_at: '2020-11-08T11:10:05.000000Z',
    updated_at: '2020-11-08T11:10:05.000000Z'
};

export const auth_users: AuthResults[] = [
    {
        Authorization:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjE1ODQyNDEsImlhdCI6MTU5MDA0ODIzNiwic3ViIjoxfQ.Xvo-hJuQyz6I5-Co7Ezs3LFWUYjGmGTTlon1Bm7ANKk',
        message: 'Connexion reussi',
        password: 'string',
        data: {
            user: connected_user,
        }
    }
];

export const list_users: User[] = [
    {
        id: 1,
        name: 'Admin',
        surname: 'admin',
        role: 'ADMIN_ROLE',
        email: null,
        phone: '699999999',
        login: 'admin@lespioles.com',
        email_token: null,
        firebase_token: null,
        verified: true,
        lock: false,
        created_at: '2020-11-08T11:10:05.000000Z',
        updated_at: '2020-11-08T11:10:05.000000Z'
    },
];

export const connected_information = {
    status: 'success',
    message: 'Connecté(e) avec succès.',
    Authorization:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjcwNDg3MjYsImlhdCI6MTU5NTUxMjcyMSwic3ViIjoxLCJwdWJsaWNfaWQiOiJmOTA0ZGMxNS1hMjU1LTQxNjMtYWRjMS0xYTUzYmMwZjZlMjIifQ.SJpeeBxPq4cTEh-v13cCVYM-MnUDoKjj1cwicxDJNCM',
    data: {
        user: {
            email: 'noumbissipatrick3@gmail.com',
            phonenumber: '675897548',
            surname: 'du pont',
            is_password_changed: true,
            qualite: 'conseiller',
            public_id: 'f904dc15-a255-4163-adc1-1a53bc0f6e22'
        },
    }
};
