import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #56CCF2',
    borderRadius: 10,
    cursor: (props: any) => (props.active ? 'RejectRequest' : 'pointer'),
    padding: '12px 16px',
    margin: 16,
    '& p': {
      color: theme.palette.secondary.main
    },
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`
    }
  },
  activeMenu: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '& svg *': {
      stroke: '#fff'
    },
    '& p': {
      color: '#fff'
    }
  },
  icon: {
    height: 100,
    '& svg': {
      height: 100
    }
  }
}));

interface Props {
  icon: string;
  onClick: any;
  title: string;
  active: any;
}

export default function (props: Props) {
  const { icon, onClick, title, active } = props;
  const classes = useStyles({ active });

  return (
    <Box
      className={clsx(classes.root, active && classes.activeMenu)}
      onClick={onClick}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}>
      <ReactSVG className={classes.icon} src={icon} alt="" />
      <Box component={'p'} mt={2}>
        {title}
      </Box>
    </Box>
  );
}
