//cli_import_section
import {
  MarkHousingsAsPaidAction,
  MARK_HOUSINGS_AS_PAID,
  MarkHousingsAsPaidActionParams
} from 'store/types';
import {
  SetAccountingListAction,
  SET_ACCOUNTING_LIST,
  SetAccountingListActionParams
} from 'store/types';

export {};

//cli_action_section
export const markHousingsAsPaidAction = (
  data: MarkHousingsAsPaidActionParams
): MarkHousingsAsPaidAction => ({
  type: MARK_HOUSINGS_AS_PAID,
  data
});

export const setAccountingListAction = (
  data: SetAccountingListActionParams
): SetAccountingListAction => ({
  type: SET_ACCOUNTING_LIST,
  data
});
