import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Logo } from 'components';
import navConfig from 'routes/navigationConfig';
import NavItem from './NavItem';
import styles from './NavBar.module.scss';
import { ApplicationState } from 'store/types';
import palette from '../../../../theme/palette';

function renderNavItems(props: any) {
  const { items, ...rest } = props;
  return (
    <List disablePadding>
      {items.reduce(
        (acc: any, item: any) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes(props: any) {
  const { acc, pathname, item, depth = 0 } = props;
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    let item_component = (
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
    acc.push(item_component);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

//@ts-ignore
function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state: ApplicationState) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar>
        <Box
          component={'div'}
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}>
          <Hidden lgUp>
            <Box p={2} display="flex" justifyContent="center">
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
          </Hidden>
          <Divider />
          <Box p={2}>
            {navConfig
              .filter(
                (s) =>
                  s.roles.includes(user?.role ?? '') ||
                  user?.role === 'ROOT_ROLE'
              )
              .map((config) => (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {config.subheader}
                    </ListSubheader>
                  }>
                  {renderNavItems({
                    items: config.items.filter(
                      (s) =>
                        s.roles.includes(user?.role ?? '') ||
                        user?.role === 'ROOT_ROLE'
                    ),
                    pathname: location.pathname
                  })}
                </List>
              ))}
          </Box>
          <Box component={'div'} flexGrow={1} />
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

export default NavBar;
