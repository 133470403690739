/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GeneralSettingsState,
  SET_GENERAL_SETTINGS
} from '../types/GeneralSettingsTypes';
import { ApplicationAction } from '../types';

const initialSetting = {
  loading: true,
  loading_fail: false,
  data: [],
  all: [],
  keyword: '',
  page: 1,
  per_page: 10
};

const initialState: GeneralSettingsState = {
  equipments: initialSetting,
  housing_type: initialSetting,
  room: initialSetting,
  tag_providers: initialSetting,
  type_of_city: initialSetting
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case SET_GENERAL_SETTINGS: {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
