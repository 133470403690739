import BaseService from './BaseService';
import { UserUrls } from './urls';

class UsersService {
  static change_role = (password: string, role: string, user_id: number) => {
    return BaseService.putRequest(
      UserUrls.CHANGE_ROLE(user_id),
      { password: password, role: role },
      true
    );
  };

  static rest_password = (password: string, user_id: number) => {
    return BaseService.putRequest(
      UserUrls.RESET_PASSWORD(user_id),
      { password: password },
      true
    );
  };

  static list_users = (page: number, keyword: string, role: string) => {
    return BaseService.getRequest(
      UserUrls.LIST_USERS(page, keyword, role),
      true
    );
  };
  static add_user = (data: any) => {
    return BaseService.postRequest(UserUrls.ADD_USER(), data, true);
  };
}

export default UsersService;
