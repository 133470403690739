export default {
  translation: {
    'shared.french': 'Francais',
    'shared.english': 'English',
    'shared.internet_connexion_error': 'Check your internet connection!',
    settings_title: 'Title',
    settings_description: 'Description',
    settings_actions: 'Actions',
    settings_add_title: 'Add',
    settings_update_title: 'Modification',
    settings_add_action: 'Add',
    settings_cancel_action: 'Cancel',
    settings_validate_action: 'Validate',
    settings_update_action: 'Modify',
    settings_equipments: 'Equipment',
    settings_room: 'Room type',
    settings_housing_type: 'Type of housing',
    settings_type_of_city: 'Type of property',
    title_fr: 'Title in French',
    title_en: 'Title in English',
    description_fr: 'Description in French',
    description_en: 'Description in English',
    title_fr_error: 'The title in French is required',
    title_en_error: 'English title is required',
    description_fr_error: 'French description is required',
    description_en_error: 'English description is required',
    yes_choice: 'Yes',
    no_choice: 'No',
    visibility_field: 'Visible',
    dialog_confirmation: 'Confirmation',
    dialog_confirm_delete: 'Do you really want to delete this element?',
    type_of_city: 'Property type',
    auto_complete_loading: 'Loading',
    auto_complete_empty: 'No results found',
    auto_complete_keyword_empty: 'Please enter a keyword',
    visibility_require: 'Visibility required',
    list_cities: 'List of cities',
    cities_found: 'Find properties',
    find_text: 'Search for...',
    housing: 'Housing',
    handle_city_delete: 'Do you really want to delete this city?',
    city_location: 'City',
    city_district: 'Neighborhood',
    city_location_require: 'Please enter the City of the property',
    city_district_require: 'Please enter the neighborhood of the property',
    city_geo_require:
      "Please click and then select the property's location on the map",
    city_geo: 'Position of the property on the map',
    find_location: 'Search for a location...',
    more_information: 'Details',
    detail_city_tab_image: 'Images',
    detail_city_tab_housing: 'Housing',
    asset_dialog_title: 'Image property',
    is_panorama_require: 'Is this a panoramic image or not?',
    description_en_require:
      'Please provide a description of the image in French',
    panoramic_image: 'Panoramic image',
    asset_dialog_save: 'Save',
    asset_drop_zone_label: 'Click to select or drag and drop images',
    asset_drop_zone_file_error: 'Some of your files are not valid images',
    asset_confirm_dialog_delete: 'Do you really want to delete this image?',
    dialog_action_confirm: 'Confirm',
    dialog_action_cancel: 'Cancel',
    asset_manager_save_button: 'Save changes',
    add_housing_price: 'Price',
    price_max_attemp: 'Maximum price at the moment is 170 000 FCFA',
    price_require: 'Price required',
    add_housing_button: 'Add an accommodation',
    quantity: 'Number of items',
    quantity_req: 'Please enter a number',
    item_req: 'Please select an item',
    page_user_title: 'Users',
    page_user_thead_name: 'Name and first name',
    page_user_role: 'Role',
    page_user_email: 'E-mail',
    page_user_phone: 'Phone number',
    page_edit_map_in_title: 'Location of the provider',
    page_user_actions: 'Actions',
    page_user_amount: 'Amount',
    page_user_action_reset_account: 'Reset',
    page_user_action_update_amount: 'Credit the account',
    page_user_action_update_amount_regex_msg: 'Please enter a valid amount',
    page_user_action_update_amount_success_msg:
      'Transaction completed successfully',
    page_user_reset_password_text: 'Enter new user password',
    MANAGER_ROLE: 'Manager',
    GUEST_ROLE: 'Client',
    ADMIN_ROLE: 'Administrator',
    ROOT_ROLE: 'Administrator',
    manager_name: 'Name of the janitor or landlord',
    manager_contact: 'Contact of the janitor or landlord',
    manager_name_error: 'Required field',
    manager_contact_error: 'Required field',
    global_label_search: 'Search',
    add_user_btn: 'Add',
    add_user_name: 'Name',
    add_user_surname: 'First name',
    add_user_genre: 'Gender',
    add_user_genre_male: 'Male',
    add_user_genre_female: 'Female',
    add_user_email: 'Email',
    add_user_login: 'Login',
    add_user_phone: 'Phone',
    add_user_role: 'Role',
    add_user_password: 'Password',
    add_user_admin_password: 'Password admin',
    publish_in_french: 'Publish in French',
    publish_in_english: 'Publish in English',
    manager_name_search: 'Name of the manager',
    auto_complete_empty_health_personal: 'No manager found',
    facebook_publish_button: 'Publish to Facebook',
    accomodation_start_date: 'From',
    accomodation_search: 'Search',
    accomodation_add_to: 'Add on',
    accomodation_pay_at: 'Pay on',
    accomodation_by: 'Add by ',
    accomodation_pay_at_action: 'Mark as pay',
    price_max: 'The maximum price is 200 000 FCFA',
    accomodation_end_date: 'To',
    additional_information: 'Additional information'
  }
};
