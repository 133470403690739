import { ThunkAction } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../types';
import {
  loadUsersError,
  loadUsersRequest,
  loadUsersSuccess
} from '../../store/actions';
import { UsersService } from '../../services/api';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>; //! write documentation here for this line

export const loadUsers = (
  page: number,
  keyword: string,
  role: string,
  enqueueSnackbar: Function
): Effect => async (dispatch, getState) => {
  dispatch(loadUsersRequest());

  const {
    i18n: { t = (key: string) => key }
  } = getState();

  return UsersService.list_users(page, keyword, role)
    .then(async (response: any) => {
      if (response.status === 200) {
        dispatch(loadUsersSuccess(await response.json()));
      } else {
        const { message } = await response.json();
        enqueueSnackbar(message, { variant: 'warning' });
        dispatch(loadUsersError());
      }
    })
    .catch(() => {
      dispatch(loadUsersError());
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    });
};

export const addUserEffect = (
  data: any,
  onSuccess: () => void,
  setLoading: (loading: boolean) => void,
  enqueueSnackbar: Function
): Effect => async (dispatch, getState) => {
  const {
    i18n: { t = (key: string) => key },
    users
  } = getState();
  setLoading(true);
  return UsersService.add_user(data)
    .then(async (response: any) => {
      if (response.status === 200) {
        const { message, data } = await response.json();
        enqueueSnackbar(message, { variant: 'success' });
        onSuccess();
        dispatch(loadUsersSuccess({ ...users, data: [data, ...users.users] }));
      } else {
        const { message } = await response.json();
        enqueueSnackbar(message, { variant: 'warning' });
      }
    })
    .catch(() => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    })
    .finally(() => setLoading(false));
};

export const changeUserRole = (
  password: string,
  role: string,
  user_id: number,
  setLoading: Function,
  enqueueSnackbar: Function,
  setSuccess: Function
): Effect => async (dispatch, getState) => {
  setLoading(true);
  const {
    i18n: { t = (key: string) => key }
  } = getState();

  const { users } = getState();
  const index = users.users.findIndex((item) => item.id === user_id);
  const newData = JSON.stringify(users);

  return UsersService.change_role(password, role, user_id)
    .then(async (response: any) => {
      if (response.status === 200) {
        const { message, data } = await response.json();
        const replicate = JSON.parse(newData);
        if (index !== -1) {
          replicate.users.splice(index, 1, data);
          replicate.data = replicate.users;
        }
        dispatch(loadUsersSuccess(replicate));
        setSuccess(true);
        enqueueSnackbar(message, { variant: 'success' });
      } else {
        const { message } = await response.json();
        enqueueSnackbar(message, { variant: 'warning' });
      }
    })
    .catch(() => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    })
    .finally(() => setLoading(false));
};

export const resetPassword = (
  password: string,
  user_id: number,
  setLoading: Function,
  enqueueSnackbar: Function,
  setSuccess: Function
): Effect => async (dispatch, getState) => {
  setLoading(true);
  const {
    i18n: { t = (key: string) => key }
  } = getState();
  const { users } = getState();
  const index = users.users.findIndex((item) => item.id === user_id);
  const newData = JSON.stringify(users);

  return UsersService.rest_password(password, user_id)
    .then(async (response: any) => {
      if (response.status === 200) {
        const { message, data } = await response.json();
        const replicate = JSON.parse(newData);
        if (index !== -1) {
          replicate.users.splice(index, 1, data);
          replicate.data = replicate.users;
        }
        dispatch(loadUsersSuccess(replicate));
        setSuccess(true);
        enqueueSnackbar(message, { variant: 'success' });
      } else {
        const { message } = await response.json();
        enqueueSnackbar(message, { variant: 'warning' });
      }
    })
    .catch(() => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    })
    .finally(() => setLoading(false));
};
