import React, {Component} from 'react';
import {GoogleApiWrapper, Map, mapEventHandler} from 'google-maps-react';
import config from 'config';

const mapStyles = {
    width: '100%',
    height: '100%',
    borderRadius: 5
};

interface GoogleMapProps {
    google: Readonly<{}>;
    onMapReady?: mapEventHandler;
    onTilesloaded?: mapEventHandler;
    onClick?: mapEventHandler;
    children?: React.ReactNode,
    zoom?: number
}

export class MapContainer extends Component<GoogleMapProps> {
    render() {
        return (
            <Map
                google={this.props.google}
                style={mapStyles}
                // @ts-ignore
                zoom={6}
                onClick={this.props.onClick}
                onTilesloaded={this.props.onTilesloaded}
                onReady={this.props.onMapReady}
                initialCenter={{
                    lat: 3.861651,
                    lng: 11.520706
                }}
            >{this.props.children}</Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: config.map_key || '', language : 'fr'
})(MapContainer);
