import { ThunkAction } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../types';
import { DynamicAsset } from '../../components/AssetManager/AssetManager';
import { AssetsService, CitiesService } from '../../services/api';
import { setCities } from '../actions/CitiesActions';
import { Asset } from '../types/CitiesTypes';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

interface uploadAssetEffectProps {
  assets: DynamicAsset[];
  updateDynamicAsset: (asset: DynamicAsset) => void;
  setGlobalLoader: (loader: boolean) => void;
  provider: 'city' | 'housing';
  provider_id: number;
}

export const uploadAssetEffect = (
  props: uploadAssetEffectProps
): Effect => async (dispatch, getState) => {
  const {
    i18n: { t = (key: string) => key },
    cities
  } = getState();
  const {
    assets,
    setGlobalLoader,
    updateDynamicAsset,
    provider,
    provider_id
  } = props;
  setGlobalLoader(true);
  let persistAssets = props.assets.filter((i) => typeof i.id !== 'undefined');
  for (const asset of assets) {
    if (!asset.isValid)
      updateDynamicAsset({
        ...asset,
        error: true
      });
    else if (asset.formData || asset.props_change) {
      updateDynamicAsset({
        ...asset,
        uploading: true,
        uploading_fail_message: undefined
      });
      const formData = asset.formData ? asset.formData : new FormData();
      formData.append('visible', asset.visible ? '1' : '0');
      formData.append('is_panorama', asset.is_panorama ? '1' : '0');
      formData.append('description_en', asset.description_en || '');
      formData.append('description_fr', asset.description_fr || '');
      try {
        const response = asset.formData
          ? await AssetsService.store_asset(formData, provider, provider_id)
          : await AssetsService.update_asset_props(
              asset.id || 0,
              formData,
              provider,
              provider_id
            );
        const json = await response.json();
        persistAssets.push(json.data);
        if (response.status === 200 || response.status === 201) {
          updateDynamicAsset({
            ...asset,
            id: json.data.id,
            formData: undefined,
            uploading: false,
            props_change: false,
            uploading_fail_message: undefined
          });
        } else {
          updateDynamicAsset({
            ...asset,
            uploading: false,
            uploading_fail_message: json.message
          });
        }
      } catch (e) {
        updateDynamicAsset({
          ...asset,
          uploading: false,
          uploading_fail_message: t('shared.internet_connexion_error')
        });
      }
    }
  }
  const mapDynamicToAsset: (dynamic: DynamicAsset) => Asset = (
    dynamic: DynamicAsset
  ) => {
    const asset: Asset = {
      id: dynamic.id || -1,
      url: dynamic.url || '',
      url_mini: dynamic.url_mini || '',
      url_thumbnail: dynamic.url_thumbnail || '',
      hash: dynamic.hash,
      is_panorama: dynamic.is_panorama || false,
      visible: dynamic.visible || false,
      description_fr: dynamic.description_fr || '',
      description_en: dynamic.description_en || '',
      city_id: dynamic.city_id,
      housing_id: dynamic.housing_id,
      created_at: dynamic.created_at || '',
      updated_at: dynamic.updated_at || ''
    };
    return asset;
  };

  if (props.provider === 'city') {
    dispatch(
      setCities({
        ...cities,
        data: cities.data.map((city) =>
          city.id === provider_id
            ? {
                ...city,
                assets: persistAssets.map(mapDynamicToAsset)
              }
            : city
        ),
        current_city_detail:
          cities.current_city_detail &&
          cities.current_city_detail.id === provider_id
            ? {
                ...cities.current_city_detail,
                assets: persistAssets.map(mapDynamicToAsset)
              }
            : undefined
      })
    );
  } else if (props.provider === 'housing') {
    dispatch(
      setCities({
        ...cities,
        data: cities.data.map((city) => ({
          ...city,
          housings: city.housings.map((housing) =>
            housing.id === provider_id
              ? {
                  ...housing,
                  assets: persistAssets.map(mapDynamicToAsset)
                }
              : housing
          )
        })),
        current_city_detail: cities.current_city_detail
          ? {
              ...cities.current_city_detail,
              housings: cities.current_city_detail.housings.map((housing) =>
                housing.id === provider_id
                  ? {
                      ...housing,
                      assets: persistAssets.map(mapDynamicToAsset)
                    }
                  : housing
              )
            }
          : undefined
      })
    );
  }
  setGlobalLoader(false);
};

// export const deleteAssetEffect = (
//     id: number,
//     setLoading: (loading: boolean) => void,
//     onSuccess: () => void,
//     enqueueSnackbar: Function
// )
//     : Effect => async (dispatch, getState) => {
//     const {i18n: {t = (key: string) => key}, cities} = getState();
//     setLoading(true);
//     return AssetsService.delete_asset(id)
//         .then(async (res: any) => {
//             const status = res.status === 200 || res.status === 201;
//             let json = await res.json();
//             if (status) {
//                 dispatch(setCities({
//                     ...cities,
//                     data: [...cities.data].filter((i) => i.id !==id)
//                 }))
//                 onSuccess();
//             }
//             let {message} = json;
//             enqueueSnackbar(message, {
//                 variant: status ? 'success' : 'warning'
//             });
//         })
//         .catch((err: any) => {
//             enqueueSnackbar(t('shared.internet_connexion_error'), {
//                 variant: 'error'
//             });
//         })
//         .finally(() => setLoading(false))
// };
