import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextProps } from '../InputText/InputText.component';
import {
  DatePicker,
  DatePickerView,
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import MomentUtils from '@date-io/moment';
import { InputText } from '../index';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

moment.locale('de', { week: { dow: 0 } });
const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 4,
    '& .MuiInputBase-root .MuiOutlinedInput-input': {
      paddingLeft: 14
    }
  },
  ripple: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiTouchRipple-root': {
      backgroundColor: 'inherit!important'
    }
  }
}));

export interface InputDateComponentProps extends InputTextProps {
  views?: DatePickerView[];
  timeViews?: ('hours' | 'minutes' | 'seconds')[];
  dateMomentFormat: string;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  onDateChange: (date?: Date) => void;
}

export default function (props: InputDateComponentProps) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { label, required } = props;
  const {
    views,
    onDateChange,
    timeViews,
    minDate,
    value,
    dateMomentFormat,
    startIcon,
    maxDate,
    format,
    endIcon,
    // clearContent,
    ...inputProps
  } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const opacity = label === ' ' ? 0 : 1;

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={'de'}>
        <div
          style={{
            display: 'none'
          }}>
          {props.type === 'time' ? (
            <TimePicker
              fullWidth={props.fullWidth}
              open={open}
              lang="de"
              ampm={false}
              cancelLabel={t('cancel')}
              onClose={() => setOpen(false)}
              value={!!value ? (value as any) : undefined}
              onChange={(date: any) => {
                onDateChange(date._d);
              }}
              placeholder={'hh:mm'}
              views={timeViews}
              format="HH:mm"
            />
          ) : (
            <KeyboardDatePicker
              clearable
              fullWidth={props.fullWidth}
              lang="de"
              error={props.error}
              helperText={props.helperText}
              minDate={minDate}
              maxDate={maxDate}
              value={!!value ? (value as any) : undefined}
              onChange={(date: any) => {
                onDateChange(date._d);
              }}
              format="MM/dd/yyyy"
            />
          )}
        </div>
        {props.type === 'time' ? (
          <div>
            <KeyboardTimePicker
              label={label}
              fullWidth={props.fullWidth}
              className={classes.ripple}
              lang="de"
              onChange={(date: any) => {
                if (date && date?._d instanceof Date && !isNaN(date?._d)) {
                  onDateChange(date?._d);
                } else {
                  onDateChange(undefined);
                }
              }}
              // open={open}
              // onClose={() => setOpen(false)}
              error={props.error === true ? true : undefined}
              helperText={!!props.helperText ? props.helperText : undefined}
              name={inputProps.name}
              cancelLabel={t('cancel')}
              inputVariant="outlined"
              onBlur={inputProps.onBlur}
              onError={(error, newValue) => {
                if (newValue !== value) onDateChange(newValue as any);
                // setDate(value);
              }}
              invalidDateMessage={t('invalid_date_message')}
              ampm={false}
              placeholder={inputProps.placeholder || 'hh:mm'}
              value={typeof value === 'undefined' ? null : (value as any)}
            />
          </div>
        ) : (
          <>
            <KeyboardDatePicker
              label={label}
              cancelLabel={t('cancel')}
              inputVariant={'outlined'}
              fullWidth={props.fullWidth}
              lang="de"
              error={props.error === true ? true : undefined}
              helperText={!!props.helperText ? props.helperText : undefined}
              minDate={minDate}
              maxDate={maxDate}
              value={!!value ? (value as any) : null}
              onChange={(date: any) => {
                if (date && date?._d instanceof Date && !isNaN(date?._d)) {
                  onDateChange(date?._d);
                } else {
                  onDateChange(undefined);
                }
              }}
              invalidLabel={t('invalid_date')}
              mask={'__.__.____'}
              format="DD.MM.YYYY"
              placeholder="DD.MM.YYYY"
            />
          </>
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
}
