import BaseService from './BaseService';
import { AuthUrls } from './urls';
import {
  login_mock,
  get_connected_user_info
} from 'services/mocks';

class AuthService {

  static get_userInfo = () => {
    return BaseService.getRequest(AuthUrls.GET_CONNECTED_INFO, true);
  };

  static update_userInfo = (data : any) => {
    return BaseService.putRequest(AuthUrls.UPDATE_USER_INFO, data, true);
  };

  static update_password = (data : any) => {
    return BaseService.putRequest(AuthUrls.UPDATE_PASSWORD, data, true);
  };

  static login = (info: object) => {
    if ((window as any).mocked) {
      return login_mock(info);
    } else {
      return BaseService.postRequest(AuthUrls.LOGINUSER, info, false);
    }
  };

}

export default AuthService;
