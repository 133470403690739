import React, { useState } from 'react';
import { NavBar, TopBar } from './components';
import { ErrorHandler } from 'components';
import styles from './Application.module.scss';
import { useDispatch } from 'react-redux';
import { fetchAllGeneralSettingEffect } from '../../store/effects/GeneralSettingsEffect';

const Application = (props: any) => {
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllGeneralSettingEffect());
  }, []);

  return (
    <div className={styles.root}>
      <ErrorHandler>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </ErrorHandler>
      <ErrorHandler>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </ErrorHandler>
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ErrorHandler>
              <div
                style={
                  {
                    // minHeight: '100%'
                  }
                }>
                {children}
              </div>
            </ErrorHandler>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
