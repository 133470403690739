import React, { useState } from 'react';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { FormInput } from 'components/FormBuilder';
import { Delta, Sources } from 'quill';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .ql-toolbar': {
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: `1px solid #E0E0E0`,
      '& .ql-picker-label:hover': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-label.ql-active': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-item:hover': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-item.ql-selected': {
        color: theme.palette.secondary.main
      },
      '& button:hover': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& button:focus': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& .ql-stroke': {
        stroke: theme.palette.text.primary
      },
      '& .ql-picker': {
        color: theme.palette.text.primary
      },
      '& .ql-picker-options': {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: 'none',
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius
      }
    },
    '& .ql-container': {
      border: 'none',
      '& .ql-editor': {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        '&.ql-blank::before': {
          color: theme.palette.text.secondary
        }
      }
    }
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

interface QuillEditorProps {
  className?: string;
  value: string;
  placeholder?: string;
  theme?: string;
  field: FormInput;
  onChange: (content: string) => void;
}

function QuillEditor(props: QuillEditorProps) {
  const {
    className,
    value,
    field: { show_paper = true, ...field },
    onChange,
    ...rest
  } = props;
  const classes = useStyles();
  // @ts-ignore
  const [length, setLength] = useState<number>(null);

  const input = () => (
    <ReactQuill
      value={value}
      style={{ minHeight: 170 }}
      className={clsx(classes.root, className)}
      onChange={(
        content: string,
        delta: Delta,
        source: Sources,
        // @ts-ignore
        editor: ReactQuill.UnprivilegedEditor
      ) => {
        if (field.maxChar) {
          if (
            !(editor.getLength() > field.maxChar) ||
            editor.getLength() < length
          ) {
            onChange(content);
            setLength(editor.getLength());
          } else {
            onChange(value);
          }
        } else {
          onChange(value);
        }
      }}
      {...rest}
    />
  );

  return (
    <Box>
      <Card
        elevation={show_paper ? 2 : 0}
        style={show_paper ? {} : { border: '1px solid #E0E0E0', padding: 0 }}>
        <CardContent style={show_paper ? {} : { padding: 0 }}>
          <Box mb={1}>
            <Typography
              style={{
                color: '#546E7A',
                fontSize: 14,
                fontWeight: 500
              }}
              variant="subtitle2"
              color="textSecondary">
              {field.label}
            </Typography>
          </Box>
          {show_paper ? <Paper variant="outlined">{input()}</Paper> : input()}
        </CardContent>
      </Card>
      <Box
        component={'div'}
        mt={1}
        color={field.maxChar && length >= field.maxChar && '#e53935'}>
        {field.maxChar ? field.maxChar + ' caractères espaces compris' : ''}
      </Box>
    </Box>
  );
}

// @ts-ignore
export default QuillEditor;
