import React, { useRef, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  withStyles
} from '@material-ui/core';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';
import { CalendarToday } from '@material-ui/icons';

const CssTextField = withStyles({
  root: {
    borderRadius: '10px !important',
    height: 40
  }
})(TextField);

export default function DateField(props: any) {
  const fileInputRef = useRef(null);
  const { onChange, value, maxDate, className, initValue, ...rest } = props;
  const [date, setDate] = useState(initValue ? initValue : new Date());
  const [open, setOpen] = useState(false);

  return (
    <div className={className}>
      <Box component={'div'} onClick={() => setOpen(true)}>
        <CssTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => setOpen(true)}>
                  <CalendarToday color={'primary'} />
                </IconButton>
              </InputAdornment>
            )
          }}
          value={value}
          type={'text'}
          {...rest}
        />
      </Box>
      <div style={{ display: 'none' }}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={'fr'}>
          <DatePicker
            maxDate={maxDate && maxDate}
            open={open}
            onClose={() => setOpen(false)}
            ref={fileInputRef}
            fullWidth
            value={date}
            onChange={(date: any) => {
              const newDate = date._d;
              const m = moment(newDate);
              onChange(m.format('YYYY-MM-DD'));
              setDate(date._d);
            }}
            inputVariant="outlined"
            views={['date', 'month', 'year']}
            format="YYYY-MM-DD"
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}
