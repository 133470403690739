import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    main: '#00A8FF',
  },
  secondary: {
    contrastText: white,
    main: '#333333',
  },
  dark_blue: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#263238',
    secondary: '#263238',

    link: '#56CCF2',
    dark: '#000000',
    darkblue: '#1A3B59',
    subtitle: '#999999',
    blueLight: '#CDD8E4',
    orange: '#FFA06A',
    violet: '#B44FE3'
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#ffffff',
    paper: white
  },
  divider: colors.grey[200]
};
