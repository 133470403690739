/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Fragment, lazy, Suspense } from 'react';
import AuthLayout from 'layouts/Auth';
import ApplicationLayout from 'layouts/Application';
import { RouteConfig } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from 'components';
import AuthGuard from './AuthGuard';
import GuestGuard from './GuestGuard';

const routesConfig: RouteConfig[] = [
  // {
  //   path: '*',
  //   exact: true,
  //   component: lazy(() => import('views/Maintain'))
  // },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/app/cities" />
  },
  {
    path: '/auth',
    layout: AuthLayout,
    guard: GuestGuard,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/auth/login/Login.page'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/app',
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: '/app/home',
        exact: true,
        component: lazy(() => import('views/cities/Cities.page'))
      },
      {
        path: '/app/profile',
        exact: true,
        component: lazy(() => import('views/auth/profile/Profile.page'))
      },
      {
        path: '/app/cities',
        exact: true,
        component: lazy(() => import('views/cities/Cities.page'))
      },
      {
        path: '/app/users',
        exact: true,
        component: lazy(() => import('views/Users/Users'))
      },
      {
        path: '/app/accounting',
        exact: true,
        component: lazy(() => import('views/accounting/Accounting.page'))
      },
      {
        path: '/app/cities/:id',
        exact: true,
        component: lazy(() => import('views/cities/Detail/Detail.page'))
      },
      {
        path: '/app/settings/:route',
        exact: true,
        component: lazy(
          () => import('views/GeneralSettings/GeneralSettings.page')
        )
      }
    ]
  }
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
