import {
  BarChart,
  Home as HomeIcon,
  Tool,
  User,
  DollarSign
} from 'react-feather';
import { GroupOutlined } from '@material-ui/icons';

interface NavigationConfigConfig {
  subheader?: string;
  roles: string[];
  items: {
    title: string;
    href: string;
    icon: any;
    roles: string[];
    items?: NavigationConfigConfig['items'];
  }[];
}

const config: NavigationConfigConfig[] = [
  {
    subheader: '',
    roles: ['ADMIN_ROLE', 'MANAGER_ROLE'],
    items: [
      {
        title: 'Citées',
        href: '/app/cities',
        icon: HomeIcon,
        roles: ['ADMIN_ROLE', 'MANAGER_ROLE']
        // items: []
      },
      // {
      //   title: 'Logements',
      //   href: '/app/housing',
      //   icon: HomeIcon,
      //   is_paid: true
      // },
      {
        title: 'Comptabilité',
        href: '/app/accounting',
        roles: ['ADMIN_ROLE'],
        icon: DollarSign
      }
    ]
  },
  {
    subheader: 'Paramètres généraux',
    roles: ['ADMIN_ROLE'],
    items: [
      {
        title: 'settings_equipments',
        href: '/app/settings/equipments',
        roles: ['ADMIN_ROLE'],
        icon: Tool
      },
      {
        title: 'settings_room',
        href: '/app/settings/room',
        roles: ['ADMIN_ROLE'],
        icon: Tool
      },
      {
        title: 'settings_housing_type',
        href: '/app/settings/housing_type',
        roles: ['ADMIN_ROLE'],
        icon: Tool
      },
      {
        title: 'settings_type_of_city',
        href: '/app/settings/type_of_city',
        roles: ['ADMIN_ROLE'],
        icon: Tool
      }
    ]
  },
  {
    subheader: 'Comptes et utilisateurs',
    roles: ['ADMIN_ROLE', 'MANAGER_ROLE'],
    items: [
      {
        title: 'Mon compte',
        href: '/app/profile',
        icon: User,
        roles: ['ADMIN_ROLE', 'MANAGER_ROLE']
        // items: []
      },
      {
        title: 'Utilisateurs',
        href: '/app/users',
        roles: ['ADMIN_ROLE'],
        icon: GroupOutlined
      },
      {
        title: 'Clients',
        href: '/app/clients',
        roles: ['ADMIN_ROLE'],
        icon: GroupOutlined
      }
    ]
  }
];
export default config;
