import React from 'react';
import {
  Formik,
  Form,
  FormikErrors,
  FormikTouched,
  FormikHelpers,
  FormikValues
} from 'formik';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import * as Yup from 'yup';
import styles from './FormBuilder.module.scss';
import {
  AsyncAutocomplete,
  ErrorHandler,
  FilesDropZone,
  InputDate,
  InputText,
  SettingQuantityProvider
} from 'components';
import { InputTextProps } from '../vendor/InputText/InputText.component';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  GridProps,
  SvgIconProps,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FileResource } from '../FilesDropZone/FilesDropZone';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AsyncAutocompleteProps } from '../vendor/AsyncAutocomplete/AsyncAutocomplete';
import GoogleLocationProvider from '../GoogleLocationProvider';
import { SettingQuantityProviderProps } from '../SettingQuantityProvider/SettingQuantityProvider';
import { DatePickerView } from '@material-ui/pickers';

export interface FormClasses {
  readonly root?: string;
}

export interface MultiSelectOption {
  id: string | number;
  label: string | number;
  data?: any;
}

export interface SelectOption {
  readonly value?: string | number;
  readonly title?: string | number;
}

export interface RadioOption {
  readonly value?: string | number | boolean;
  readonly title?: string | number;
}

export interface FormInput {
  readonly name: string;
  readonly type?:
    | 'text'
    | 'typography'
    | 'number'
    | 'auto_complete'
    | 'async_auto_complete'
    | 'date'
    | 'password'
    | 'tel'
    | 'multi_item_with_quantity'
    | 'location'
    | 'checkbox'
    | 'email'
    | 'link'
    | 'large_checkbox'
    | 'select'
    | 'radio'
    | 'file_drop_zone';
  readonly label?: string;
  readonly show_paper?: boolean;
  readonly maxChar?: number;

  readonly description?: React.ReactNode;
  readonly placeholder?: string;
  readonly asyncAutoCompleteProps?: AsyncAutocompleteProps;
  readonly multiItemQuantityProps?: SettingQuantityProviderProps;
  readonly selectOptions?: SelectOption[];
  readonly radioOptions?: RadioOption[];
  readonly variant?: 'standard' | 'outlined' | 'filled' | undefined;
  readonly startIcon?: React.ReactElement<SvgIconProps>;
  readonly classname?: string;
  readonly maxDate?: Date;
  readonly orientation?: 'row' | 'column';
  readonly inputProps?: InputTextProps;
  readonly containerProps?: GridProps;
  readonly changeState?: Function;
  readonly autoFocus?: boolean;
  readonly minDate?: Date;
  readonly dateMomentFormat?: string;
  readonly timeViews?: ('hours' | 'minutes' | 'seconds')[];
  readonly views?: DatePickerView[];
  readonly setUrl?: Function;
  readonly imgSrc?: string;
  readonly fullWidth?: boolean;
  readonly autocompleteOptions?: MultiSelectOption[];
  readonly setAutocompleteSelected?: (value: MultiSelectOption) => void;
  readonly autocompleteMultiple?: boolean;
}

export interface UseFormBuilderProps {
  readonly fields: FormInput[];
  formRef?: React.RefObject<HTMLFormElement>;
  readonly validations: Object;
  readonly initialValues?: Object;
  readonly formProps?: Object;
  initialTouched?: FormikTouched<FormikValues>;
  readonly formClasses?: FormClasses;
  readonly onSubmit: (values: Object, formikHelpers: FormikHelpers<any>) => any;
  readonly renderSubmit?: (
    isSubmitting: boolean,
    errors: FormikErrors<Object>,
    touched: FormikTouched<Object>,
    setFieldTouched: Function,
    handleSubmit: Function,
    values: Object
  ) => any;
}

const useStyles = makeStyles((theme) => ({
  dropZone: {}
}));

const FormBuilder = React.forwardRef<HTMLFormElement, UseFormBuilderProps>(
  (props, formRef) => {
    const {
      // formRef,
      initialValues,
      initialTouched = {},
      validations = {},
      formProps = {},
      formClasses = {},
      fields = [],
      onSubmit = () => {},
      renderSubmit
    } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    return (
      <Formik
        initialTouched={initialTouched}
        initialValues={initialValues || {}}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values, actions);
        }}
        validationSchema={Yup.object().shape(validations as any)}
        render={({
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          errors,
          setErrors,
          touched,
          setFieldTouched,
          isSubmitting,
          handleSubmit
        }) => (
          <Form
            ref={formRef}
            className={clsx(styles.root, formClasses.root)}
            {...formProps}>
            <Grid
              container
              component={'div'}
              alignItems={'stretch'}
              spacing={2}>
              {fields
                .filter((field) => field)
                .map(({ type = 'text', ...field }) => (
                  <Grid
                    item
                    style={{
                      display: field?.inputProps?.hidden === true ? 'none' : '',
                      ...(field.containerProps?.style || {})
                    }}
                    {...(field.containerProps || {})}
                    component={'div'}>
                    {['checkbox'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box mb={2}>
                          <FormControlLabel
                            labelPlacement={'top'}
                            control={
                              <RadioGroup row={field?.orientation !== 'column'}>
                                {(field.radioOptions || []).map(
                                  (radioOption) => (
                                    <FormControlLabel
                                      value={radioOption.value}
                                      control={
                                        <Checkbox
                                          checked={
                                            (
                                              ((values as any)[field.name] ||
                                                []) as RadioOption[]
                                            ).findIndex(
                                              (i) =>
                                                i.value === radioOption.value
                                            ) !== -1
                                          }
                                          onChange={(e) => {
                                            e.persist();
                                            let initialsValues = ((
                                              values as any
                                            )[field.name] ||
                                              []) as RadioOption[];
                                            if (
                                              initialsValues.findIndex(
                                                (i) =>
                                                  i.value === radioOption.value
                                              ) !== -1
                                            )
                                              initialsValues =
                                                initialsValues.filter(
                                                  (i) =>
                                                    i.value !==
                                                    radioOption.value
                                                );
                                            else
                                              initialsValues.push(radioOption);
                                            setFieldValue(
                                              field.name,
                                              initialsValues
                                            );
                                          }}
                                          name={field.name}
                                          color="primary"
                                          value={(values as any)[field.name]}
                                        />
                                      }
                                      label={t(`${radioOption.title}`)}
                                    />
                                  )
                                )}
                              </RadioGroup>
                            }
                            label={!!field.label && `${t(field.label)}`}
                            //{...(field.inputProps || {})}
                          />
                          {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}
                        </Box>
                      </ErrorHandler>
                    ) : ['date', 'time'].indexOf(type) !== -1 ? (
                      <Box mb={2} component={'div'}>
                        <InputDate
                          maxDate={field.maxDate}
                          onBlur={handleBlur}
                          views={field.views || []}
                          dateMomentFormat={
                            field.dateMomentFormat ||
                            (i18n.language === 'fr'
                              ? 'DD/MM/YYYY'
                              : 'YYYY-MM-DD')
                          }
                          onDateChange={(date) =>
                            setFieldValue(field.name, date)
                          }
                          error={Boolean(
                            (touched as any)[field.name] &&
                              (errors as any)[field.name]
                          )}
                          fullWidth={field.fullWidth || false}
                          startIcon={field.startIcon}
                          // endIcon={field.endIcon}
                          helperText={
                            (touched as any)[field.name] &&
                            (errors as any)[field.name]
                          }
                          type={type}
                          label={field.label}
                          name={field.name}
                          value={(values as any)[field.name]}
                          {...(field.inputProps || {})}
                        />
                      </Box>
                    ) : ['text', 'number', 'email', 'password'].indexOf(
                        type
                      ) !== -1 ? (
                      <InputText
                        error={Boolean(
                          (touched as any)[field.name] &&
                            (errors as any)[field.name]
                        )}
                        fullWidth={field.fullWidth || false}
                        startIcon={field.startIcon}
                        autoFocus={field.autoFocus || false}
                        helperText={
                          (touched as any)[field.name] &&
                          (errors as any)[field.name]
                        }
                        label={field.label}
                        name={field.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={type}
                        value={(values as any)[field.name]}
                        {...(field.inputProps || {})}
                      />
                    ) : ['async_auto_complete'].indexOf(type) !== -1 &&
                      field.asyncAutoCompleteProps ? (
                      <ErrorHandler>
                        <Box mb={2}>
                          <AsyncAutocomplete
                            {...field.asyncAutoCompleteProps}
                            value={(values as any)[field.name]}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                        </Box>
                      </ErrorHandler>
                    ) : ['multi_item_with_quantity'].indexOf(type) !== -1 &&
                      field.multiItemQuantityProps ? (
                      <ErrorHandler>
                        <Box mb={2}>
                          <Typography
                            variant={'h5'}
                            style={{
                              marginBottom: 6
                            }}>
                            {t(`${field.label}`)}
                            {field?.inputProps?.required ? '*' : ''}
                          </Typography>
                          <SettingQuantityProvider
                            {...field.multiItemQuantityProps}
                            error={Boolean(
                              (touched as any)[field.name] &&
                                (errors as any)[field.name]
                            )}
                            values={(values as any)[field.name]}
                            onChange={(values: any) =>
                              setFieldValue(field.name, values)
                            }
                          />
                          {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}
                        </Box>
                      </ErrorHandler>
                    ) : ['location'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box mb={2}>
                          <GoogleLocationProvider
                            name={field.name}
                            onTouch={() => setFieldTouched(field.name, true)}
                            value={(values as any)[field.name]}
                            onChange={(location) => {
                              setFieldValue(field.name, location);
                            }}
                            label={field.label || ''}
                          />
                          {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}
                        </Box>
                      </ErrorHandler>
                    ) : ['typography'].indexOf(type) !== -1 ? (
                      <Box mb={2}>
                        <Typography variant={'body2'}>
                          {field?.description}
                        </Typography>
                      </Box>
                    ) : ['radio'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box mb={2}>
                          <FormControlLabel
                            labelPlacement={'top'}
                            control={
                              <RadioGroup
                                row
                                value={(values as any)[field.name]}>
                                {(field.radioOptions || []).map(
                                  (radioOption) => (
                                    <FormControlLabel
                                      value={radioOption.value}
                                      control={
                                        <Radio
                                          checked={
                                            (values as any)[field.name] ===
                                            radioOption.value
                                          }
                                          onChange={(event, value) => {
                                            setFieldValue(
                                              field.name,
                                              radioOption.value
                                            );
                                            field.changeState &&
                                              field.changeState(
                                                radioOption.value
                                              );
                                          }}
                                          color={'primary'}
                                        />
                                      }
                                      label={t(`${radioOption.title}`)}
                                    />
                                  )
                                )}
                              </RadioGroup>
                            }
                            label={!!field.label && t(`${field.label}`)}
                            //{...(field.inputProps || {})}
                          />
                          {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}
                        </Box>
                      </ErrorHandler>
                    ) : ['select'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box mb={2}>
                          <FormControl
                            disabled={field?.inputProps?.disabled || undefined}
                            error={Boolean(
                              (touched as any)[field.name] &&
                                (errors as any)[field.name]
                            )}
                            onBlur={() => handleBlur(field.name)}
                            fullWidth={field.fullWidth || false}
                            // autoFocus={field.autoFocus || false}
                            variant={field.variant || 'outlined'}
                            //    {...(field.inputProps || {})}
                          >
                            <InputLabel id={field.name}>
                              {field.label}
                            </InputLabel>
                            <Select
                              labelId={field.name}
                              id={field.name}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={(values as any)[field.name]}
                              onChange={handleChange}
                              label={field.label}>
                              {(field.selectOptions || []).map(
                                (selectOption) => (
                                  <MenuItem
                                    key={selectOption.value}
                                    value={selectOption.value}>
                                    {selectOption.title}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}
                        </Box>
                      </ErrorHandler>
                    ) : ['auto_complete'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box component={'div'} mb={2}>
                          <FormControl
                            margin={field?.inputProps?.margin}
                            onBlur={handleBlur}
                            fullWidth={field.fullWidth || false}
                            variant={field.variant || 'outlined'}>
                            <Typography
                              variant={'h5'}
                              style={{
                                marginBottom: 6
                              }}>
                              {t(`${field.label}`)}
                              {field?.inputProps?.required ? '*' : ''}
                            </Typography>
                            <Autocomplete
                              noOptionsText={'Aucun choix disponible'}
                              loadingText={'Chargement'}
                              id={field.name}
                              multiple={field.autocompleteMultiple}
                              filterSelectedOptions={true}
                              getOptionDisabled={
                                field.autocompleteMultiple
                                  ? (option) =>
                                      (
                                        (values as any)[field.name] || []
                                      ).findIndex(
                                        (i: MultiSelectOption) =>
                                          i.id === option.id
                                      ) !== -1
                                  : (option) =>
                                      option.id === (values as any)[field.name]
                              }
                              getOptionLabel={(option) => {
                                if (
                                  typeof option === 'number' ||
                                  typeof option === 'string'
                                ) {
                                  const selected = (
                                    field.autocompleteOptions || []
                                  ).find((i: any) => i.id === option);
                                  return selected ? selected.label : '';
                                } else {
                                  return option.label || '';
                                }
                              }}
                              options={field.autocompleteOptions || []}
                              renderOption={(option) => (
                                <Typography variant={'h5'}>
                                  {option.label}
                                </Typography>
                              )}
                              value={(values as any)[field.name]}
                              onChange={(e: any, value: any) => {
                                if (value) {
                                  setFieldValue(
                                    field.name,
                                    field.autocompleteMultiple
                                      ? value
                                      : value.id
                                  );
                                }
                                if (field.setAutocompleteSelected)
                                  field.setAutocompleteSelected(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  helperText={
                                    (touched as any)[field.name] &&
                                    (errors as any)[field.name]
                                  }
                                  error={Boolean(
                                    (touched as any)[field.name] &&
                                      (errors as any)[field.name]
                                  )}
                                  {...params}
                                  autoFocus={field.autoFocus || false}
                                  // label={field.label}
                                  variant={field.variant || 'outlined'}
                                />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </ErrorHandler>
                    ) : ['file_drop_zone'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box
                          pb={2}
                          className={classes.dropZone}
                          component={'div'}>
                          <FilesDropZone
                            setUrl={(resource: FileResource) =>
                              field.setUrl && field.setUrl(resource)
                            }
                            name={field.name}
                            field={field}
                            defaultImgSrc={field.imgSrc && field.imgSrc}
                            onChange={(content) =>
                              setFieldValue(field.name, content)
                            }
                          />
                          {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}
                        </Box>
                      </ErrorHandler>
                    ) : ['link'].indexOf(type) !== -1 ? (
                      <ErrorHandler>
                        <Box component={'div'} mb={2}>
                          <Typography variant="h5">
                            {' '}
                            <Link underline={'always'}>
                              {field.description}
                            </Link>
                          </Typography>
                        </Box>
                      </ErrorHandler>
                    ) : (
                      <div />
                    )}
                  </Grid>
                ))}
              {renderSubmit &&
                renderSubmit(
                  isSubmitting,
                  errors,
                  touched,
                  setFieldTouched,
                  handleSubmit,
                  values
                )}
            </Grid>
          </Form>
        )}
      />
    );
  }
);

export default FormBuilder;
