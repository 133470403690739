import {CitiesResult, SET_CITIES} from "../types/CitiesTypes";
import {ApplicationAction} from "../types";


const initialState: CitiesResult = {
    current_page: 1,
    data: [],
    per_page: '10',
    loading: true,
    loading_fail: false
};



const reducer = (state = initialState, action: ApplicationAction) => {
    switch (action.type) {
        case SET_CITIES: {
            return action.data;
        }
        default: {
            return state;
        }
    }
}

export default reducer;