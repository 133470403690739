import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
//import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: `2px dashed #DDDDDD`,
    padding: '16px 16px',
    outline: 'none',
    height: '100%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    border: `2px dashed ${theme.palette.action.active}`,
    opacity: 0.5
  }
}));

export const isFileImage = (type: string) =>
  [
    'image/jpeg',
    'image/png',
    'image/webp',
    'webp',
    'png',
    'jpg',
    'jpeg'
  ].includes(type);

interface FileResource {
  url: string;
  formData: FormData;
}

interface AssetDropZone {
  onChange: (values: FileResource[]) => void;
}
export default function (props: AssetDropZone) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.findIndex((file) => !isFileImage(file.type)) !== -1) {
        enqueueSnackbar(t('asset_drop_zone_file_error'), {
          variant: 'error'
        });
      } else {
        props.onChange(
          acceptedFiles.map((file, i) => {
            const formData = new FormData();
            formData.append('image', file);
            return {
              formData,
              url: URL.createObjectURL(acceptedFiles[i])
            };
          })
        );
      }
    },
    [props.onChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });
  return (
    <div
      className={clsx({
        [classes.dropZone]: true,
        [classes.dragActive]: isDragActive
      })}
      {...getRootProps()}>
      <input {...getInputProps()} />
      <Box component={'div'} textAlign={'center'}>
        <React.Fragment>
          <Typography variant="h5" gutterBottom color={'primary'}>
            {t('asset_drop_zone_label')}
          </Typography>
          <CloudUploadOutlined color={'primary'} />
        </React.Fragment>
      </Box>
    </div>
  );
}
