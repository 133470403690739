export default {
  translation: {
    'shared.french': 'Francais',
    'shared.english': 'Anglais',
    'shared.internet_connexion_error': 'Vérifiez votre connexion Internet!',
    settings_title: 'Titre',
    settings_description: 'Description',
    settings_actions: 'Actions',
    settings_add_title: 'Ajout',
    settings_update_title: 'Modification',
    settings_add_action: 'Ajouter',
    settings_cancel_action: 'Annuler',
    settings_validate_action: 'Valider',
    settings_update_action: 'Modifier',
    settings_equipments: 'Equipements',
    settings_room: 'Type de pièces',
    settings_housing_type: 'Type de logement',
    settings_type_of_city: 'Type de propriété',
    title_fr: 'Titre en Français',
    title_en: 'Titre en Anglais',
    description_fr: 'Description en Français',
    description_en: 'Description en Anglais',
    title_fr_error: 'Le titre en français est requis',
    title_en_error: 'Le titre en anglais est requis',
    description_fr_error: 'La description en français est requis',
    description_en_error: 'La description en anglais est requis',
    yes_choice: 'Oui',
    no_choice: 'Non',
    visibility_field: 'Visible',
    dialog_confirmation: 'Confirmation',
    dialog_confirm_delete: 'Souhaittez-vous vraiment supprimer cet element ?',
    type_of_city: 'Type de propriété',
    auto_complete_loading: 'Chargement',
    auto_complete_empty: 'Auccun résultat trouver',
    auto_complete_keyword_empty: 'Veuillez saisir un mot cléf',
    visibility_require: 'La visibiliter les requise',
    list_cities: 'Liste des cite',
    cities_found: 'Propriétés trouver ',
    find_text: 'Recherche...',
    housing: 'Logements',
    handle_city_delete: 'Voulez-vous vraiment supprimer cette cité ?',
    city_location: 'Ville',
    city_district: 'Quartier',
    city_location_require: 'Veuillez saisir la Ville de la propriété',
    city_district_require: 'Veuillez saisir le quartier de la propriété',
    city_geo_require:
      'Veuillez cliquer puis choisir la position de la propriété sur la carte',
    city_geo: 'Position de la propriété sur la carte',
    find_location: 'Rechercher un emplacement...',
    more_information: 'Détails',
    detail_city_tab_image: 'Images',
    detail_city_tab_housing: 'Logements',
    asset_dialog_title: "Propriété de l'image",
    is_panorama_require: "Sagit t'il d'une image panoramique ou non ?",
    description_fr_require:
      "Veuillez fournir une description de l'image en français",
    description_en_require:
      "Veuillez fournir une description de l'image en français",
    panoramic_image: 'Image panoramique',
    asset_dialog_save: 'Sauvegarder',
    asset_drop_zone_label: 'CLiquez pour choisir ou glissez déposer des images',
    asset_drop_zone_file_error:
      'Certains de vos fichiers ne sont pas des images valides',
    asset_confirm_dialog_delete:
      'Voullez-vous vraiment supprimer cette image ?',
    dialog_action_confirm: 'Confirmer',
    dialog_action_cancel: 'Annuler',
    asset_manager_save_button: 'Sauvegarder les modification',
    add_housing_price: 'Prix',
    price_max_attemp: 'Prix maximum pour le moment est de 170 000 FCFA',
    price_require: 'Prix requis',
    add_housing_button: 'Ajouter un logement',
    quantity: "Nombre d'éléments",
    quantity_req: 'Veuillez entrer un nombre',
    item_req: 'Veuillez selectiner un élément',
    page_user_title: 'Utilisateurs',
    page_user_thead_name: 'Nom et prénom',
    page_user_role: 'Role',
    page_user_email: 'E-mail',
    page_user_phone: 'N° de téléphone',
    page_edit_map_in_title: 'Emplacement du fournisseur ',
    page_user_actions: 'Actions',
    page_user_amount: 'Montant',
    page_user_action_reset_account: 'Réinitialiser',
    page_user_action_update_amount: 'Créditer le compte',
    page_user_action_update_amount_regex_msg:
      'Veuillez entrer un montant valide',
    page_user_action_update_amount_success_msg:
      'Transaction effectué avec succès',
    page_user_reset_password_text:
      "Entrez le nouveau mot de passe de l'utilisateur",

    MANAGER_ROLE: 'Manageur',
    GUEST_ROLE: 'Client',
    ADMIN_ROLE: 'Administrateur',
    ROOT_ROLE: 'Administrateur',
    manager_name: 'Nom du concierge ou bailleur',
    manager_contact: 'Contact du concierge ou bailleur',
    manager_name_error: 'Champ requis',
    manager_contact_error: 'Champ requis',
    global_label_search: 'Recherche',
    add_user_btn: 'Ajouter',
    add_user_name: 'Nom',
    add_user_surname: 'Prénom',
    add_user_genre: 'Genre',
    add_user_genre_male: 'Masculin',
    add_user_genre_female: 'Feminin',
    add_user_email: 'Email',
    add_user_login: 'Login',
    add_user_phone: 'Téléphone',
    add_user_role: 'Rôle',
    add_user_password: 'Mot de passe',
    add_user_admin_password: 'Mot de passe admin',
    publish_in_french: 'Publier en Français',
    publish_in_english: 'Publier en Anglais',
    manager_name_search: 'Nom du manageur',
    auto_complete_empty_health_personal: 'Aucun manageur trouver',
    facebook_publish_button: 'Publier sur Facebook',
    accomodation_start_date: 'Du',
    accomodation_search: 'Rechercher',
    accomodation_add_to: 'Ajouter le ',
    accomodation_pay_at: 'Payer le ',
    accomodation_by: 'Ajouter par ',
    accomodation_pay_at_action: 'Marquer comme payer',
    price_max: 'Le prix maximum est de 200 000 FCFA',
    accomodation_end_date: 'Au',
    additional_information: 'Informations supplémentaires'
  }
};
