import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DynamicAsset } from '../AssetManager';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import * as Yup from 'yup';
import { FormInput } from '../../FormBuilder';
import { FormikErrors, FormikTouched } from 'formik';
import { ButtonPrimary, FormBuilder } from '../../index';
//import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    margin: '0 auto',
    maxHeight: 250,
    borderRadius: 5,
    maxWidth: '100%',
    objectFit: 'cover',
    marginBottom: 24
  }
}));

interface DialogAssetConfig {
  asset: DynamicAsset;
  onChange: (asset: DynamicAsset) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function (props: DialogAssetConfig) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const onClose = () => props.setOpen(false);
  const ref = React.createRef<HTMLButtonElement>();
  const fields: FormInput[] = [
    {
      name: 'description_fr',
      fullWidth: true,
      label: 'description_fr',
      type: 'text',
      variant: 'outlined',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12
      }
    },
    {
      name: 'description_en',
      fullWidth: true,
      label: 'description_en',
      type: 'text',
      variant: 'outlined',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12
      }
    },
    {
      name: 'visible',
      fullWidth: true,
      label: 'visibility_field',
      type: 'radio',
      radioOptions: [
        {
          value: true,
          title: 'yes_choice'
        },
        {
          value: false,
          title: 'no_choice'
        }
      ],
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12
      }
    },
    {
      name: 'is_panorama',
      fullWidth: true,
      label: 'panoramic_image',
      type: 'radio',
      radioOptions: [
        {
          value: true,
          title: 'yes_choice'
        },
        {
          value: false,
          title: 'no_choice'
        }
      ],
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12
      }
    }
  ];

  const validations: Object = {
    visible: Yup.boolean().required('visibility_require'),
    is_panorama: Yup.boolean().required('is_panorama_require'),
    description_fr: Yup.string().max(255).required('description_fr_require'),
    description_en: Yup.string().max(255).required('description_en_require')
  };
  return (
    <Dialog open={props.open} onClose={onClose}>
      <DialogTitle>{t('asset_dialog_title')}</DialogTitle>
      <DialogContent>
        <img src={props.asset.url} className={classes.image} alt="" />

        <FormBuilder
          // ref={ref}
          fields={fields}
          validations={validations}
          initialValues={{
            visible:
              typeof props.asset.visible == 'undefined'
                ? true
                : props.asset.visible,
            is_panorama:
              typeof props.asset.is_panorama === 'undefined'
                ? false
                : props.asset.is_panorama,
            description_en: props.asset.description_en,
            description_fr: props.asset.description_fr
          }}
          formProps={{
            noValidate: false
          }}
          renderSubmit={(
            isSubmitting,
            errors: FormikErrors<Object>,
            touched: FormikTouched<Object>,
            setFieldTouched: Function,
            handleSubmit: Function
          ) => (
            <Button
              ref={ref}
              style={{
                display: 'none'
              }}
              onClick={() => {
                handleSubmit();
                fields.forEach((field) => {
                  setFieldTouched(field.name, true);
                });
              }}
            />
          )}
          onSubmit={async (values: any) => {
            props.onChange({
              ...props.asset,
              visible: values.visible,
              is_panorama: values.is_panorama,
              description_en: values.description_en,
              description_fr: values.description_fr,
              props_change: true,
              isValid: true,
              error: false
            });
            onClose();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={'outlined'} color={'primary'}>
          {t('settings_cancel_action')}
        </Button>
        <ButtonPrimary
          onClick={() => ref?.current?.click()}
          variant={'contained'}
          color={'primary'}>
          {t('asset_dialog_save')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}
