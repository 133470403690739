import React, { Fragment, useState } from 'react';
import styles from './Auth.module.scss';
import { TopBar } from './components';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchAllGeneralSettingEffect } from '../../store/effects/GeneralSettingsEffect';

const Auth = (props: any) => {
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllGeneralSettingEffect());
  }, []);

  return (
    <Fragment>
      <Box component={'div'} mb={5}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Box>
      <main className={styles.content}>{children}</main>
    </Fragment>
  );
};

export default Auth;
