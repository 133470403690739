/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { FormInput } from 'components/FormBuilder';
import { CloudUploadOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  dropZone: {
    border: `2px dashed #DDDDDD`,
    padding: '16px 16px',
    minHeight: 180,
    outline: 'none',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    border: `2px dashed ${theme.palette.action.active}`,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

interface FilesDropZoneProps {
  className?: string;
  defaultImgSrc?: string;
  onChange: (content: any) => void;
  dynamicNameAllocation?: boolean;
  field: FormInput;
  name: string;
  setUrl: (resource: FileResource) => void;
}

export interface FileResource {
  objectUrl?: string;
}

export const isFileImage = (type: string) =>
  [
    'image/jpeg',
    'image/png',
    'image/webp',
    'webp',
    'png',
    'jpg',
    'jpeg'
  ].includes(type);

const FilesDropzone: FunctionComponent<FilesDropZoneProps> = ({
  className,
  defaultImgSrc,
  name,
  onChange,
  field: { label, placeholder },
  setUrl,
  ...rest
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const initialResource: FileResource = {
    objectUrl: defaultImgSrc
  };
  const [newSource, setNewSource] = useState<FileResource>(initialResource);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        onChange(reader.result);
      };
      const resource = {
        objectUrl: URL.createObjectURL(acceptedFiles[0]),
        isImage: isFileImage(acceptedFiles[0].type)
      };
      setNewSource(resource);
      setUrl(resource);
    },
    [onChange, name]
  );

  const handleRemoveAll = () => {
    setFiles([]);
    onChange({});
    setNewSource(initialResource);
    setUrl(initialResource);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  const input = () => (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <Box component={'div'} textAlign={'center'}>
          <React.Fragment>
            <Typography
              style={{
                color: 'rgba(28, 28, 28,.5)'
              }}
              gutterBottom
              variant="h5"
              component={'h5'}>
              {placeholder}
            </Typography>
            <Typography variant="h5" gutterBottom color={'primary'}>
              {label}
            </Typography>
            <CloudUploadOutlined color={'primary'} />
          </React.Fragment>
        </Box>
        {!!newSource.objectUrl && (
          <Box component={'div'} textAlign={'center'}>
            <img
              src={newSource.objectUrl}
              style={{
                height: 60,
                objectFit: 'cover',
                maxWidth: '100%',
                borderRadius: 2
              }}
              alt=""
            />
          </Box>
        )}
      </div>
    </div>
  );

  return <Box>{input()}</Box>;
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
