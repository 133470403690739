import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

interface MapProps {
  address?: string;
}

const MapWithAMarker = withScriptjs(
  withGoogleMap((props: MapProps) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: -34.397, lng: 150.644 }}
      //center={props.address}
    >
      <Marker position={props.address} />
    </GoogleMap>
  ))
);

export default MapWithAMarker;
