import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DynamicAsset } from '../AssetManager';
import { Grid, IconButton, CircularProgress, Box } from '@material-ui/core';
import { orange, green } from '@material-ui/core/colors';
import {
  Edit,
  Delete,
  CloudUpload,
  RotateLeft,
  RotateRight
} from '@material-ui/icons';
import clsx from 'clsx';
// @ts-ignore
import Dots from 'material-ui-dots';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { rotateAssetEffect } from '../../../store/effects/HousingEffect';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#e3e1e1',
    borderRadius: 5,
    border: (props: CardImage) =>
      props.asset.error
        ? `2px solid ${theme.palette.error.main}`
        : '2px solid #f4f6f8',
    '&:hover': {
      '&>div': {
        opacity: 1
      }
    }
    // '&>div': {
    //   opacity: (props: CardImage) => (props.asset.uploading ? 1 : 0)
    // },
    // [theme.breakpoints.down('md')]: {
    //   '&>div': {
    //     opacity: 1
    //   }
    // }
  },
  iconButton: {
    backgroundColor: 'rgba(0,0,0,.5)'
  },
  actions: {
    top: 0,
    left: 0,
    width: '100%',
    borderRadius: 5,
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.25)',
    position: 'absolute',
    transitionDuration: '0.35s'
  },
  img: {
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5,
    width: '100%'
  },
  icon: {
    color: '#ffffff'
  },
  imgSavedContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
    height: 34,
    width: 34,
    backgroundColor: '#ffffff',
    borderRadius: 33,
    display: 'flex',
    opacity: (props: CardImage) => (props.asset.id ? 1 : 0),
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3
  },
  imgSaved: {
    width: 18,
    color: (props: CardImage) =>
      props.asset.props_change ? orange[500] : green[500]
  }
}));

interface CardImage {
  className: string;
  onDelete: () => void;
  onUpdate: () => void;
  asset: DynamicAsset;
}

export default function CardImage(props: CardImage) {
  const classes = useStyles(props);
  const { asset } = props;
  const [angle, setAngle] = useState<number>(0);
  const [loadingRotate, setLoadingRotate] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleRotate = (id: number, angle: number) => {
    dispatch(
      rotateAssetEffect(
        { id, angle },
        setLoadingRotate,
        () => {
          setAngle(angle);
        },
        enqueueSnackbar
      )
    );
  };

  return (
    <div className={clsx(props.className, classes.root)}>
      <img
        src={`${asset.url_mini}${!!angle ? `?angle=${angle}` : ''}`}
        className={classes.img}
        alt=""
      />
      <h2 className={classes.imgSavedContainer}>
        <CloudUpload className={classes.imgSaved} />
      </h2>
      <Grid
        container
        component={'div'}
        className={classes.actions}
        alignItems={'flex-end'}>
        <Grid item lg={'auto'} style={{ flexGrow: 1 }}>
          <Box ml={1}>{asset.uploading && <CircularProgress size={24} />}</Box>
        </Grid>
        {typeof props.asset.id === 'undefined' && (
          <Grid item lg={'auto'}>
            <IconButton onClick={props.onDelete}>
              <Delete className={classes.icon} />
            </IconButton>
          </Grid>
        )}
        {typeof props.asset.id !== 'undefined' && (
          <Grid item lg={'auto'}>
            <IconButton
              disabled={loadingRotate}
              onClick={() => {
                if (typeof props.asset.id !== 'undefined')
                  handleRotate(props.asset.id, angle - 90);
              }}>
              <RotateRight className={classes.icon} />
            </IconButton>
          </Grid>
        )}
        {typeof props.asset.id !== 'undefined' && (
          <Grid item lg={'auto'}>
            <IconButton
              disabled={loadingRotate}
              onClick={() => {
                if (typeof props.asset.id !== 'undefined')
                  handleRotate(props.asset.id, angle + 90);
              }}>
              <RotateLeft className={classes.icon} />
            </IconButton>
          </Grid>
        )}
        <Grid item lg={'auto'}>
          <IconButton onClick={props.onUpdate}>
            <Edit className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
