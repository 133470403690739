import { AccountingRange } from '../../store/types/AccountingType';

export const prefixer = 'https://api.lespioles.com/api/';
// export const prefixer = 'http://127.0.0.1:8000/api/';
export const nestApiUrl = 'https://nest-api.lespioles.com/';

export const AuthUrls = {
  LOGINUSER: `${prefixer}auth/login`,
  UPDATE_USER_INFO: `${prefixer}auth/update`,
  UPDATE_PASSWORD: `${prefixer}auth/update-password`,
  GET_CONNECTED_INFO: `${prefixer}auth/me`
};

export const UserUrls = {
  UPDATE_PROFILE: `${prefixer}users/update`,
  CHANGE_ROLE: (user_id: number) => `${prefixer}user/${user_id}`,
  ADD_USER: () => `${prefixer}user`,
  RESET_PASSWORD: (user_id: number) => `${prefixer}user/reset/${user_id}`,
  LIST_USERS: (
    page: number,
    keyword: string,
    role: string,
    per_page: number = 15
  ) =>
    `${prefixer}user?page=${page}&per_page=${per_page}&keyword=${keyword}&role=${role}`
};

export const GeneralSettingsUrls = {
  FETCH: (
    provider: string,
    page: string | number,
    per_page: string | number,
    keyword: string | number
  ) =>
    `${prefixer}${provider}?page=${page}&per_page=${per_page}&keyword=${keyword}`,
  ADD: (provider: string) => `${prefixer}${provider}`,
  FETCH_ALL_DATA: () => `${prefixer}user/data/all`,
  UPDATE: (provider: string) => `${prefixer}${provider}`,
  DELETE: (provider: string, id: number | string) =>
    `${prefixer}${provider}/${id}`
};

export const CitiesUrls = {
  FETCH: (
    page: string | number,
    per_page: string | number,
    keyword: string | number
  ) => `${prefixer}city?page=${page}&per_page=${per_page}&keyword=${keyword}`,
  ADD: () => `${prefixer}city`,
  UPDATE: () => `${prefixer}city`,
  FACEBOOK_PUBLISH: () => `${prefixer}city`,
  DELETE: (id: number | string) => `${prefixer}city/${id}`,
  GET: (id: number | string) => `${prefixer}city/${id}`
};

export const HousingUrls = {
  ADD: () => `${prefixer}housing`,
  UPDATE: () => `${prefixer}housing`,
  ROTATE: (id: number, angle: number) =>
    `${prefixer}asset/rotate/${id}/${angle}`,
  PUBLISH_ON_FACEBOOK: () => `${prefixer}social_media`,
  PUBLISH_ON_TWITTER: () => `${nestApiUrl}cities/twitter`
};

export const AssetsUrls = {
  ADD: (provider: 'city' | 'housing', provider_id: number) =>
    `${prefixer}asset/image?provider=${provider}&${provider}_id=${provider_id}`,
  UPDATE: (id: number, provider: 'city' | 'housing', provider_id: number) =>
    `${prefixer}asset/image/${id}?provider=${provider}&${provider}_id=${provider_id}`
};

export const AccountingUrls = {
  LIST_HOUSINGS_ACCOUNTING: (range: AccountingRange) =>
    `${prefixer}housing/admin/all?start_date=${range.start_date}&end_date=${
      range.end_date
    }&creator_id=${range.creator_id ?? ''}&housing_id=${
      range.housing_id || ''
    }`,
  MARK_HOUSINGS_AS_PAID: `${prefixer}housing/admin/paid`
};
