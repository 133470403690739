import BaseService from "./BaseService";
import {CitiesUrls} from "./urls";


class CitiesService {

    static fetch_cities = (page: string | number, per_page: string | number, keyword: string | number) => {
        return BaseService.getRequest(CitiesUrls.FETCH(page, per_page, keyword), true);
    };

    static add_city = (payload: any) => {
        return BaseService.postRequest(CitiesUrls.ADD(), payload, true);
    };

    static update_city = (payload: any) => {
        return BaseService.putRequest(CitiesUrls.UPDATE(), payload, true);
    };

    static delete_city = (id: number) => {
        return BaseService.deleteRequest(CitiesUrls.DELETE(id), {}, true);
    };

    static get_detail_city = (id: number) => {
        return BaseService.getRequest(CitiesUrls.GET(id), true);
    };
}

export default CitiesService