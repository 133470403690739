import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface ButtonPrimaryProps extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

const ButtonPrimary = forwardRef<any, ButtonPrimaryProps>((props, ref) => {
  const classes = useStyles();
  const { loading = false, disabled = false } = props;
  return (
    <Button
      ref={ref}
      className={clsx(props.className, classes.wrapper)}
      disabled={disabled || loading}
      {...props}>
      {props.children}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
});

export default ButtonPrimary;
