import { mocked } from 'utils';
import { auth_users, connected_information } from 'services/fixtures';

export const login_mock = (info: any) => {
  const { code_uai, password } = info;
  let auth_user = auth_users[0]
  return auth_user
    ? mocked(200, {
        Authorization: auth_user.Authorization,
        data: auth_user.data,
        message: auth_user.message
      })
    : mocked(401, { message: 'Code UAI ou mot de passe invalide!' });
};

export const get_connected_user_info = () => {
  return mocked(200, connected_information);
};
