import React, { useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Menu,
  MenuItem,
  withStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import withErrorHandler from 'components/ErrorHandler/withErrorHandler';

const useStyles = makeStyles((theme) => ({
  root: {},
  menuItem: {
    padding: 0
  },
  formControlLabel: {
    padding: theme.spacing(0.5, 2),
    width: '100%',
    margin: 0
  }
}));

const ButtonSelect = withStyles({
  root: {
    boxShadow: 'none !important'
  }
})(Button);

export interface simpleMultiSelectOption {
  id: string | number;
  label: string | number;
}

interface SelectProps {
  label: string;
  options: any;
  values: simpleMultiSelectOption[];
  onChange?: (values: simpleMultiSelectOption[]) => void;
  loading: boolean;
}

function SimpleMultiSelect(props: SelectProps) {
  const { label, options, values, loading, onChange } = props;
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleOptionToggle = (event: any, id: string | number) => {
    let newValue = [...values];
    if (event.target.checked) {
      const target = values.find((i) => i.id === id);
      if (target) newValue.push(target);
    } else {
      newValue = newValue.filter((item) => item.id !== id);
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <>
      <ButtonSelect
        endIcon={<ArrowDropDownIcon />}
        disabled={loading}
        onClick={handleMenuOpen}
        ref={anchorRef}>
        {label}
        {loading && <CircularProgress size={24} />}
      </ButtonSelect>
      <Menu
        anchorEl={anchorRef.current}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ style: { width: 250 } }}>
        {options.map((option: any) => (
          <MenuItem className={classes.menuItem} key={option.id}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={values.indexOf(option.id) > -1}
                  onClick={(event) => handleOptionToggle(event, option.id)}
                  value={option.id}
                  name={option.title}
                />
              }
              label={option.title}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default withErrorHandler(SimpleMultiSelect);
