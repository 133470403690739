import { ThunkAction } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../types';
import { CitiesService } from '../../services/api';
import { setCities } from '../actions/CitiesActions';
import HousingService from '../../services/api/HousingsService';
import { Housing } from '../types/CitiesTypes';
import { setAccountingListAction } from '../actions/AccountingAction';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const addHousingEffect =
  (
    payload: any,
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    enqueueSnackbar: Function
  ): Effect =>
  async (dispatch, getState) => {
    const {
      i18n: { t = (key: string) => key },
      cities
    } = getState();
    setLoading(true);
    return HousingService.add_housing(payload)
      .then(async (res: any) => {
        const status = res.status === 200 || res.status === 201;
        let json = await res.json();
        if (status) {
          dispatch(
            setCities({
              ...cities,
              data: cities.data.map((i) =>
                i.id !== payload.city_id
                  ? i
                  : {
                      ...i,
                      housings: [json.data, ...i.housings]
                    }
              ),
              current_city_detail: cities.current_city_detail
                ? {
                    ...cities.current_city_detail,
                    housings: [
                      json.data,
                      ...cities.current_city_detail.housings
                    ]
                  }
                : undefined
            })
          );
          onSuccess();
        }
        let { message } = json;
        enqueueSnackbar(message, {
          variant: status ? 'success' : 'warning'
        });
      })
      .catch((err: any) => {
        enqueueSnackbar(t('shared.internet_connexion_error'), {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };

export const updateHousingEffect =
  (
    payload: any,
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    enqueueSnackbar: Function
  ): Effect =>
  async (dispatch, getState) => {
    const {
      i18n: { t = (key: string) => key },
      cities
    } = getState();
    setLoading(true);
    return HousingService.update_housing(payload)
      .then(async (res: any) => {
        const status = res.status === 200 || res.status === 201;
        let json = await res.json();
        if (status) {
          dispatch(
            setCities({
              ...cities,
              data: cities.data.map((i) =>
                i.id !== payload.city_id
                  ? i
                  : {
                      ...i,
                      housings: i.housings.map((y) =>
                        y.id !== payload.id ? y : json.data
                      )
                    }
              ),
              current_city_detail: cities.current_city_detail
                ? {
                    ...cities.current_city_detail,
                    housings: cities.current_city_detail.housings.map((y) =>
                      y.id !== payload.id ? y : json.data
                    )
                  }
                : undefined
            })
          );
          onSuccess();
        }
        let { message } = json;
        enqueueSnackbar(message, {
          variant: status ? 'success' : 'warning'
        });
      })
      .catch((err: any) => {
        enqueueSnackbar(t('shared.internet_connexion_error'), {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };

export const rotateAssetEffect =
  (
    payload: { id: number; angle: number },
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    enqueueSnackbar: Function
  ): Effect =>
  async (dispatch, getState) => {
    const {
      i18n: { t = (key: string) => key }
    } = getState();
    setLoading(true);
    return HousingService.rotate_housing(payload.id, payload.angle)
      .then(async (res: any) => {
        const status = res.status === 200 || res.status === 201;
        let json = await res.json();
        let { message } = json;
        if (status) {
          onSuccess();
        } else
          enqueueSnackbar(message, {
            variant: 'warning'
          });
      })
      .catch((err: any) => {
        enqueueSnackbar(t('shared.internet_connexion_error'), {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };

export const publishHousingEffect =
  (
    lang: string,
    setLoading: (loading: boolean) => void,
    enqueueSnackbar: Function,
    housing?: Partial<Housing>,
    media: 'facebook' | 'twitter' = 'facebook'
  ): Effect =>
  async (dispatch, getState) => {
    const {
      i18n: { t = (key: string) => key },
      cities,
      accounting: { housings, housings_range }
    } = getState();
    setLoading(true);
    const payload = {
      housing_id: housing?.id,
      locale: lang
    };
    return (
      media === 'facebook'
        ? HousingService.publish_on_facebook(payload)
        : HousingService.publish_on_twitter(payload)
    )
      .then(async (res: any) => {
        const status = res.status === 200 || res.status === 201;
        let json = await res.json();
        if (status) {
          dispatch(
            setAccountingListAction({
              housings: housings.map((h) =>
                h.id !== housing?.id
                  ? h
                  : {
                      ...h,
                      [media === 'facebook'
                        ? 'facebook_post_id'
                        : 'twitter_post_id']: json?.data?.payload?.id ?? '1'
                    }
              ),
              range: housings_range
            })
          );
          dispatch(
            setCities({
              ...cities,
              data: cities.data.map((i) =>
                i.id !== housing?.city_id
                  ? i
                  : {
                      ...i,
                      housings: i.housings.map((y) =>
                        y.id !== housing?.id
                          ? y
                          : {
                              ...y,
                              [media === 'facebook'
                                ? 'facebook_post_id'
                                : 'twitter_post_id']:
                                json?.data?.payload?.id ?? '1'
                            }
                      )
                    }
              ),
              current_city_detail: cities.current_city_detail
                ? {
                    ...cities.current_city_detail,
                    housings: cities.current_city_detail.housings.map((y) =>
                      y.id !== housing?.id
                        ? y
                        : {
                            ...y,
                            [media === 'facebook'
                              ? 'facebook_post_id'
                              : 'twitter_post_id']:
                              json?.data?.payload?.id ?? '1'
                          }
                    )
                  }
                : undefined
            })
          );
        }
        let { message } = json;
        enqueueSnackbar(message, {
          variant: status ? 'success' : 'warning'
        });
      })
      .catch((err: any) => {
        enqueueSnackbar(t('shared.internet_connexion_error'), {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };
