import {IMapProps, Map} from "google-maps-react";
import {mocked} from "../../utils";

export interface MapConfig {
    mapProps: IMapProps | undefined,
    map: any | undefined
}

class GoogleService {

    static fetch_place = async (keyword: string, mapConfig: MapConfig | undefined) => {
        return new Promise((resolve, reject) => {
            if (mapConfig && mapConfig.mapProps) {
                const {map} = mapConfig;
                const {google} = mapConfig.mapProps;
                const service = new google.maps.places.AutocompleteService();
                const sessionToken = new google.maps.places.AutocompleteSessionToken();
                let request = {
                    input: keyword,
                    sessionToken,
                    componentRestrictions: {country: 'cm'}
                };
                service.getPlacePredictions(request, function (results: any, status: any) {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        return resolve({
                            status: 200,
                            json: () => new Promise((resolve, reject) => {
                                resolve(results)
                            })
                        });
                    } else {
                        return reject({
                            status: 500,
                            json: () => new Promise((resolve, reject) => {
                                resolve([])
                            })
                        });
                    }
                });
            } else {
                return reject({
                    status: 500,
                    json: () => new Promise((resolve, reject) => {
                        resolve([])
                    })
                });
            }
        });
    };
}

export default GoogleService