import typography from '../typography';

export default {
  label: typography.h5,
  root: {
    paddingLeft: 0
  },
  labelPlacementTop: {
    alignItems: 'flex-start !important',
    marginLeft: '0 !important'
  }
};
