import { Action } from 'redux';
import { ShortTitle } from './GeneralSettingsTypes';

export const SET_CITIES = 'SET_CITIES';

export interface SetCities extends Action {
  type: typeof SET_CITIES;
  data: CitiesResult;
}

export interface CitiesServerData {
  current_page: number;
  data: City[];
  last_page?: number;
  per_page: string;
  total?: number;
}
export interface CitiesResult extends CitiesServerData {
  loading: boolean;
  loading_fail: boolean;
  fail_message?: string;
  current_city_detail?: City;
}

export interface SimpleCity {
  id: number;
  title_fr: string;
  title_en: string;
  visible: boolean;
  type_of_city_id: number;
  created_at: string;
  updated_at: string;
  type_of_city: ShortTitle;
  city: string;
  district: string;
  longitude: number;
  latitude: number;
  false_longitude: number;
  false_latitude: number;
}

export interface City extends SimpleCity {
  housings: Housing[];
  assets: Asset[];
}

export interface Asset {
  id: number;
  url: string;
  url_mini: string;
  url_thumbnail: string;
  hash?: string;
  is_panorama: boolean;
  visible: boolean;
  description_fr?: string;
  description_en?: string;
  city_id?: number;
  housing_id?: number;
  created_at: string;
  updated_at: string;
}

export interface SimpleHousing {
  id: number;
  false_longitude: string;
  false_latitude: string;
  price: string;
  price_of_visit: number | null;
  visible: boolean;
  housing_type_id: number;
  city_id: number;
  twitter_post_id?: string;
  facebook_post_id?: string;
  created_at: string;
  paid_at: string;
  updated_at: string;
}

export interface Housing extends SimpleHousing {
  equipments?: HousingType[];
  rooms?: HousingType[];
  visits_count: number;
  favorites_count: number;
  watch_count: number;
  housing_type?: HousingType;
  assets?: Asset[];
}

export interface HousingElement {
  id: number;
  false_longitude: string;
  false_latitude: string;
  price: string;
  price_of_visit: null;
  visible: boolean;
  housing_type_id: number;
  city_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface HousingType {
  id: number;
  title_fr: string;
  title_en: string;
  description_fr?: null | string;
  description_en?: null | string;
  visible: boolean;
  created_at: Date;
  updated_at: Date;
  pivot?: Pivot;
}

export interface Pivot {
  housing_id: number;
  equipment_id?: number;
  room_id?: number;
  quantity?: number;
}
