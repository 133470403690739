import BaseService from './BaseService';
import { AccountingUrls, HousingUrls } from './urls';
import { AccountingRange } from '../../store/types/AccountingType';

class HousingService {
  static add_housing = (payload: any) => {
    return BaseService.postRequest(HousingUrls.ADD(), payload, true);
  };

  static update_housing = (payload: any) => {
    return BaseService.putRequest(HousingUrls.UPDATE(), payload, true);
  };

  static rotate_housing = (id: number, angle: number) => {
    return BaseService.postRequest(HousingUrls.ROTATE(id, angle), {}, true);
  };

  static fetch_housings_accommodations = (range: AccountingRange) => {
    return BaseService.getRequest(
      AccountingUrls.LIST_HOUSINGS_ACCOUNTING(range),
      true
    );
  };

  static mark_housings_paid = (housings: number[]) => {
    return BaseService.putRequest(
      AccountingUrls.MARK_HOUSINGS_AS_PAID,
      { housings },
      true
    );
  };

  static publish_on_facebook = (payload: any) => {
    return BaseService.postRequest(
      HousingUrls.PUBLISH_ON_FACEBOOK(),
      payload,
      true
    );
  };
  static publish_on_twitter = (payload: any) => {
    return BaseService.postRequest(
      HousingUrls.PUBLISH_ON_TWITTER(),
      payload,
      true
    );
  };
}

export default HousingService;
