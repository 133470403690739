import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralSettingsProvider } from '../../store/types/GeneralSettingsTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/types';
import {
  Box,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ButtonPrimary, FormBuilder } from '../index';
import { FormikErrors, FormikTouched } from 'formik';
import {
  addCityEffect,
  updateCityEffect
} from '../../store/effects/CitiesEffect';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../FormBuilder';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 18px',
    borderRadius: 5,
    border: (props: SettingQuantityProviderProps) =>
      `1px solid ${
        props.error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)'
      }`,
    display: 'flex',
    flexWrap: 'nowrap'
  },
  chip: {
    margin: '8px 4px'
  }
}));

export interface SettingQuantityItem {
  id: number | string;
  quantity: number | string;
}

export interface SettingQuantityProviderProps {
  values?: SettingQuantityItem[];
  onChange?: (values: SettingQuantityItem[]) => void;
  provider: GeneralSettingsProvider;
  error?: boolean;
}

export default function (props: SettingQuantityProviderProps) {
  const classes = useStyles(props);
  const data = useSelector((state: ApplicationState) => state.general_setting);
  const list = data[props.provider.route];
  const [open, setOpen] = useState<boolean>(false);
  const { values } = props;
  const ref = React.createRef<HTMLButtonElement>();
  const onClose = () => {
    setOpen(false);
    setInitialState(undefined);
  };
  const { t, i18n } = useTranslation();
  const [initialState, setInitialState] = useState<
    SettingQuantityItem | undefined
  >();
  const fields: FormInput[] = [
    {
      name: 'id',
      fullWidth: true,
      label: `settings_${props.provider.route}`,
      type: 'auto_complete',
      autocompleteMultiple: false,
      autocompleteOptions: list.all
        .filter(
          (i) =>
            (props.values || []).findIndex((y) => y.id === i.id) === -1 ||
            i.id === initialState?.id
        )
        .map((i) => ({
          id: i.id || -1,
          label: i18n.language === 'fr' ? i.title_fr : i.title_en
        })),
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12
      }
    },
    {
      name: 'quantity',
      fullWidth: true,
      label: 'quantity',
      type: 'text',
      variant: 'outlined',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12
      }
    }
  ];
  const validations: Object = {
    quantity: Yup.number().required('quantity_req'),
    id: Yup.number().required('item_req')
  };

  return (
    <div>
      <div className={classes.root}>
        <Box flexGrow={1} component={'div'} display={'flex'} flexWrap={'wrap'}>
          {[...(values || [])].map((i, k) => {
            const data = list.all.find((y) => y.id === i.id);
            return (
              data && (
                <Chip
                  className={classes.chip}
                  onDelete={() =>
                    props.onChange &&
                    props.onChange(
                      [...(values || [])].filter((y, j) => y.id !== data.id)
                    )
                  }
                  onClick={() => setInitialState(i)}
                  label={`${
                    i18n.language === 'fr' ? data.title_fr : data.title_en
                  }(${i.quantity})`}
                />
              )
            );
          })}
        </Box>
        <Box ml={'26px'}>
          <IconButton onClick={() => setOpen(true)}>
            <Add color={'primary'} />
          </IconButton>
        </Box>
      </div>
      <Dialog
        open={open || typeof initialState !== 'undefined'}
        onClose={onClose}>
        <DialogTitle>
          {t(initialState ? 'settings_update_title' : 'settings_add_title')}
        </DialogTitle>
        <DialogContent>
          <FormBuilder
            // ref={ref}
            fields={fields}
            validations={validations}
            initialValues={{
              id: initialState?.id,
              quantity: initialState?.quantity
            }}
            formProps={{
              noValidate: true
            }}
            renderSubmit={(
              isSubmitting,
              errors: FormikErrors<Object>,
              touched: FormikTouched<Object>,
              setFieldTouched: Function,
              handleSubmit: Function
            ) => (
              <Button
                style={{
                  display: 'none'
                }}
                ref={ref}
                onClick={() => {
                  handleSubmit();
                  fields.forEach((field) => {
                    setFieldTouched(field.name, true);
                  });
                }}
              />
            )}
            onSubmit={async (values: any) => {
              if (initialState) {
                props.onChange &&
                  props.onChange(
                    (props.values || []).map((i) =>
                      i.id === initialState.id ? values : i
                    )
                  );
              } else
                props.onChange &&
                  props.onChange([values, ...(props.values || [])]);
              onClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant={'outlined'} color={'primary'}>
            {t('settings_cancel_action')}
          </Button>
          <ButtonPrimary
            onClick={() => ref?.current?.click()}
            variant={'contained'}
            color={'primary'}>
            {t(initialState ? 'settings_update_action' : 'settings_add_action')}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </div>
  );
}
