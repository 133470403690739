import produce from 'immer';
import { ApplicationAction } from 'store/types';
import { AccountingState } from 'store/types';
//cli_import_section
import { MARK_HOUSINGS_AS_PAID } from 'store/types';
import { SET_ACCOUNTING_LIST } from 'store/types';
import moment from 'moment';

const initialState: AccountingState = {
  housings: []
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    //cli_use_cases_section
    case MARK_HOUSINGS_AS_PAID: {
      return {
        ...state,
        housings: state.housings.map((h) =>
          action.data.housing_ids.includes(h.id)
            ? {
                ...h,
                paid_at: moment().toISOString()
              }
            : h
        )
      };
    }
    case SET_ACCOUNTING_LIST: {
      return {
        ...state,
        housings: action.data.housings,
        housings_range: action.data.range
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
