import { ThunkAction } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from '../types';
import {
  GeneralSettingsProvider,
  LongTitle,
  ShortTitle
} from '../types/GeneralSettingsTypes';
import GeneralSettingsService from '../../services/api/GeneralSettingsService';
import { setGeneralSettings } from '../actions/GeneralSettingsActions';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const fetchGeneralSettingsEffect = (
  page: string | number,
  per_page: string | number,
  keyword: string | number,
  provider: GeneralSettingsProvider,
  enqueueSnackbar: Function
): Effect => async (dispatch, getState) => {
  const {
    i18n: { t = (key: string) => key },
    general_setting
  } = getState();
  const previewRequest = general_setting[provider.route];

  dispatch(
    setGeneralSettings({
      ...general_setting,
      [provider.route]: {
        ...general_setting[provider.route],
        loading: true,
        loading_fail: false,
        page,
        per_page,
        keyword
      }
    })
  );
  return GeneralSettingsService.fetch_general_settings(
    provider.route,
    page,
    per_page,
    keyword
  )
    .then(async (res: any) => {
      let { data, message } = await res.json();
      const status = res.status === 200 || res.status === 201;
      if (status) {
        return dispatch(
          setGeneralSettings({
            ...general_setting,
            [provider.route]: {
              ...general_setting[provider.route],
              loading: false,
              loading_fail: false,
              data: data
            }
          })
        );
      } else {
        dispatch(
          setGeneralSettings({
            ...general_setting,
            [provider.route]: {
              ...general_setting[provider.route],
              page: previewRequest.page,
              per_page: previewRequest.per_page,
              keyword: previewRequest.keyword,
              loading: false,
              loading_fail: true
            }
          })
        );
        if (previewRequest.data.length !== 0)
          enqueueSnackbar(t(message), {
            variant: 'warning'
          });
      }
    })
    .catch((err: any) => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
      dispatch(
        setGeneralSettings({
          ...general_setting,
          [provider.route]: {
            ...general_setting[provider.route],
            page: previewRequest.page,
            per_page: previewRequest.per_page,
            keyword: previewRequest.keyword,
            loading: false,
            loading_fail: true
          }
        })
      );
    });
};

export const addGeneralSettingsEffect = (
  data: ShortTitle | LongTitle,
  setLoading: (loading: boolean) => void,
  provider: GeneralSettingsProvider,
  enqueueSnackbar: Function,
  onSuccess: () => void
): Effect => async (dispatch, getState) => {
  setLoading(true);
  const {
    i18n: { t = (key: string) => key },
    general_setting
  } = getState();

  return GeneralSettingsService.add_general_setting(provider.route, data)
    .then(async (res: any) => {
      let { data, message } = await res.json();
      const status = res.status === 200 || res.status === 201;
      if (status) {
        enqueueSnackbar(message, {
          variant: 'success'
        });
        onSuccess();
        return dispatch(
          setGeneralSettings({
            ...general_setting,
            [provider.route]: {
              ...general_setting[provider.route],
              data: [data, ...general_setting[provider.route].data],
              all: [data, ...general_setting[provider.route].all]
            }
          })
        );
      }
      enqueueSnackbar(t(message), {
        variant: status ? 'success' : 'warning'
      });
    })
    .catch((err: any) => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    })
    .finally(() => setLoading(false));
};

export const fetchAllGeneralSettingEffect = (): Effect => async (
  dispatch,
  getState
) => {
  const {
    i18n: { t = (key: string) => key },
    general_setting
  } = getState();
  return GeneralSettingsService.fetch_all_settings_data().then(
    async (res: any) => {
      const status = res.status === 200 || res.status === 201;
      if (status) {
        let {
          equipments,
          room,
          tag_providers,
          housing_type,
          type_of_city
        } = await res.json();
        return dispatch(
          setGeneralSettings({
            ...general_setting,
            equipments: {
              ...general_setting.equipments,
              all: equipments
            },
            type_of_city: {
              ...general_setting.type_of_city,
              all: type_of_city
            },
            tag_providers: {
              ...general_setting.tag_providers,
              all: tag_providers
            },
            room: {
              ...general_setting.room,
              all: room
            },
            housing_type: {
              ...general_setting.housing_type,
              all: housing_type
            }
          })
        );
      }
    }
  );
};

export const updateGeneralSettingsEffect = (
  payload: ShortTitle | LongTitle,
  setLoading: (loading: boolean) => void,
  provider: GeneralSettingsProvider,
  enqueueSnackbar: Function,
  onSuccess: () => void
): Effect => async (dispatch, getState) => {
  setLoading(true);
  const {
    i18n: { t = (key: string) => key },
    general_setting
  } = getState();

  return GeneralSettingsService.update_general_setting(provider.route, payload)
    .then(async (res: any) => {
      let { data, message } = await res.json();
      const status = res.status === 200 || res.status === 201;
      if (status) {
        enqueueSnackbar(message, {
          variant: 'success'
        });
        onSuccess();
        return dispatch(
          setGeneralSettings({
            ...general_setting,
            [provider.route]: {
              ...general_setting[provider.route],
              data: [...general_setting[provider.route].data].map((i) =>
                i.id === payload.id ? data : i
              ),
              all: [...general_setting[provider.route].all].map((i) =>
                i.id === payload.id ? data : i
              )
            }
          })
        );
      }
      enqueueSnackbar(t(message), {
        variant: status ? 'success' : 'warning'
      });
    })
    .catch((err: any) => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    })
    .finally(() => setLoading(false));
};
export const deleteGeneralSettingsEffect = (
  id: number,
  setLoading: (loading: boolean) => void,
  provider: GeneralSettingsProvider,
  enqueueSnackbar: Function,
  onSuccess: () => void
): Effect => async (dispatch, getState) => {
  setLoading(true);
  const {
    i18n: { t = (key: string) => key },
    general_setting
  } = getState();

  return GeneralSettingsService.delete_general_setting(provider.route, id)
    .then(async (res: any) => {
      let { data, message } = await res.json();
      const status = res.status === 200 || res.status === 201;
      if (status) {
        enqueueSnackbar(message, {
          variant: 'success'
        });
        onSuccess();
        return dispatch(
          setGeneralSettings({
            ...general_setting,
            [provider.route]: {
              ...general_setting[provider.route],
              data: [...general_setting[provider.route].data].filter(
                (i) => i.id !== id
              ),
              all: [...general_setting[provider.route].all].filter(
                (i) => i.id !== id
              )
            }
          })
        );
      }
      enqueueSnackbar(t(message), {
        variant: status ? 'success' : 'warning'
      });
    })
    .catch((err: any) => {
      enqueueSnackbar(t('shared.internet_connexion_error'), {
        variant: 'error'
      });
    })
    .finally(() => setLoading(false));
};
