import BaseService from "./BaseService";
import {AssetsUrls, CitiesUrls} from "./urls";
import {DynamicAsset} from "../../components/AssetManager/AssetManager";


class CitiesService {

    static store_asset = (formData: FormData,  provider: 'city' | 'housing', provider_id: number) => {
        return BaseService.postFileRequest(AssetsUrls.ADD(provider, provider_id), formData,true);
    };

    static update_asset_props = (id: number, formData: FormData,  provider: 'city' | 'housing', provider_id: number) => {
        return BaseService.postFileRequest(AssetsUrls.UPDATE(id, provider, provider_id), formData,true);
    };

    // static delete_asset = (id: number) => {
    //     return BaseService.deleteRequest(AssetsUrls.DELETE(id), {}, true);
    // };
}

export default CitiesService