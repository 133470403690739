/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import styles from './TopBar.module.scss';
import { Logo } from 'components';
import CustomMenu from './Menu';

interface TopBarProps {
  onMobileNavOpen: any;
  className?: string;
}

const TopBar = (props: TopBarProps) => {
  const { onMobileNavOpen, className, ...rest } = props;
  const [choiceModeOpen, setChoiceModeOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const tablet = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <AppBar className={clsx(styles.root, className)} elevation={0} {...rest}>
        <CustomMenu
            open={choiceModeOpen}
            onClose={() => setChoiceModeOpen(false)}
        />
        <Toolbar className={styles.toolbar}>
          <Hidden lgUp>
            <IconButton
                className={styles.menuButton}
                color="inherit"
                onClick={onMobileNavOpen}>
              <SvgIcon fontSize="small">
                <MenuIcon/>
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <Logo
                onClick={() =>
                    history.push('/')
                }
                className={styles.logo}
            />
          </Hidden>
        </Toolbar>
      </AppBar>
  );
};

export default TopBar;
