import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const app_theme = (locale : any) =>  {
  const theme = createMuiTheme({
    palette,
    typography,
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1320,
        xl: 1920
      }
    }
  }, locale);
  theme.overrides = {
    MuiCssBaseline: {
      '@global': {}
    },
    ...overrides
  };
  return theme;
}

export default app_theme;
