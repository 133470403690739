import { Action } from 'redux';

export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';

export interface ShortTitle {
  id?: number;
  visible?: boolean;
  title_fr: string;
  title_en: string;
  description_fr?: string;
  description_en?: string;
}

export interface LongTitle extends ShortTitle {}

interface SettingLoading {
  loading: boolean;
  loading_fail: boolean;
  page: number | string;
  per_page: number | string;
  keyword: string;
}

export interface ShortSetting extends SettingLoading {
  data: ShortTitle[];
  all: ShortTitle[];
}

export interface LongSetting extends SettingLoading {
  data: LongTitle[];
  all: LongTitle[];
}

export interface GeneralSettingsState {
  equipments: LongSetting;
  room: ShortSetting;
  tag_providers: LongSetting;
  housing_type: LongSetting;
  type_of_city: LongSetting;
}

export interface GeneralSettingsProvider {
  route:
    | 'equipments'
    | 'room'
    | 'tag_providers'
    | 'housing_type'
    | 'type_of_city';
}

export interface SetGeneralSettings extends Action {
  type: typeof SET_GENERAL_SETTINGS;
  data: GeneralSettingsState;
}
