import { Action } from 'redux';
import { User } from './AccountTypes';

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';

export interface LoadingUsersState {
  users: boolean;
}

export interface UsersState {
  loading: LoadingUsersState;
  users: User[];
  current_page: number;
  total: number;
  last_page: number;
}

export interface LoadUsersRequest extends Action {
  type: typeof LOAD_USERS_REQUEST;
}

export interface LoadUsersSuccess extends Action {
  type: typeof LOAD_USERS_SUCCESS;
  data: {
    current_page: number;
    total: number;
    data: User[];
    last_page: number;
  };
}

export interface LoadUsersError extends Action {
  type: typeof LOAD_USERS_ERROR;
}
