import React from 'react';
import {Provider} from 'react-redux';
import {createStyles, jssPreset, makeStyles, StylesProvider, ThemeProvider} from '@material-ui/styles';
import {create} from 'jss';
import rtl from 'jss-rtl';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SnackbarProvider} from 'notistack';
import store from 'store';
import Routes from 'routes';
import 'translations';
import {ScrollReset} from 'components';
import Auth from 'routes/Auth';
import 'styles/index.scss';
import {InternationalisationTypes} from "./store/types/InternationalisationTypes";
import {enUS, frFR} from '@material-ui/core/locale';
import {useTranslation} from "react-i18next";


// import * as Sentry from '@sentry/react';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Sentry.init({
//   dsn:
//     'https://931b5098c2b24cf6a22e9f03cb25173a@o258987.ingest.sentry.io/2544112'
// });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      '& .MuiFormControlLabel-labelPlacementTop':{
        alignItems: 'flex-start',
        marginLeft: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

const App = () => {
  useStyles();
  const {i18n} = useTranslation();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme(i18n.language === InternationalisationTypes.FR ? frFR : enUS)}>
        <StylesProvider jss={jss}>
          <React.Fragment>
            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Auth>
                <CssBaseline />
                <ScrollReset />
                <Routes />
              </Auth>
            </SnackbarProvider>
          </React.Fragment>
        </StylesProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
