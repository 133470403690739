import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormInput } from '../../../../../components/FormBuilder';
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  Box,
  IconButton,
  DialogTitle
} from '@material-ui/core';
import * as Yup from 'yup';
import { ApplicationState } from '../../../../../store/types';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../../../../components';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

interface UpdateAccountProps {
  open: boolean;
  setOpen: (o: boolean) => void;
}

export default function UpdateAccount(props: UpdateAccountProps) {
  const classes = useStyles();
  const formRef = React.createRef<HTMLFormElement>();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [formAction, setFormAction] = useState({
    setErrors: (data: object) => {},
    setStatus: (data: object) => {},
    setSubmitting: (status: boolean) => {}
  });
  const {
    loading: { signup_failed, signup_failed_message, signup_success },
  } = useSelector((state: ApplicationState) => state.account);

  React.useEffect(() => {
    if (signup_failed) {
      formAction.setSubmitting(false);
      formAction.setStatus({ success: false });
      formAction.setErrors({ submit: signup_failed_message });
      enqueueSnackbar(signup_failed_message, {
        variant: 'error'
      });
    }
  }, [
    enqueueSnackbar,
    signup_failed,
    signup_failed_message,
    signup_success,
    formAction
  ]);

  const initialValues = {
    submit: false
  };
  const fields: FormInput[] = [
    {
      name: 'referer_name',
      fullWidth: true,
      label: 'Nom et prénom',
      placeholder: 'DUPONT Jean',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12,
        sm: 12
      }
    },
    {
      name: 'referer_email',
      fullWidth: true,
      label: 'E-mail',
      placeholder: 'jean.dupont@millameet.com',
      type: 'email',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12,
        sm: 12
      }
    },
    {
      name: 'referer_qualite',
      fullWidth: true,
      label: 'Qualité',
      placeholder: 'Chargé de communication',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12,
        sm: 12
      }
    },
    {
      name: 'referer_phonenumber',
      fullWidth: true,
      label: 'Numéro de téléphone',
      placeholder: '00 00 00 00',
      type: 'tel',
      inputProps: {
        margin: 'normal'
      },
      containerProps: {
        xs: 12,
        sm: 12
      }
    }
  ];
  const validations: Object = {
    referer_email: Yup.string()
      .min(1, "L'email du référent est requis")
      .email('Fournir une adresse email valide')
      .max(255, 'Doit être au maximum 255 caractères')
      .required("L'email du référent est requis"),
    referer_name: Yup.string()
      .min(1, 'Le nom et prénom du référent est requis')
      .max(255, 'Doit être au maximum 255 caractères')
      .required('Le nom et prénom du référent est requis'),
    referer_qualite: Yup.string()
      .min(1, "Votre université ou groupe d'appartenance est requis")
      .max(255, 'Doit être au maximum 255 caractères')
      .required("Votre université ou groupe d'appartenance est requis"),
    referer_phonenumber: Yup.string()
      .matches(
        /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim,
        'Fournir un numéro de téléphone valide'
      )
      .min(1, 'Le numéro de téléphone du référent est requis')
      .max(20, 'Doit être au maximum 20 caractères')
      .required('Le numéro de téléphone du référent est requis')
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          position: 'relative'
        }
      }}
      open={props.open}
      onClose={() => props.setOpen(false)}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Box component={'div'} flexGrow={1} />
          <div>
            <Box lineHeight={'28px'} fontWeight={500} fontSize={24}>
              Editer mon profil
            </Box>
          </div>
          <Box component={'div'} flexGrow={1} />
          <IconButton
            onClick={() => {
              props.setOpen(false);
            }}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {signup_failed && (
          <Box mt={2}>
            <Alert severity="error">
              <div>{signup_failed_message}</div>
            </Alert>
          </Box>
        )}
        <FormBuilder
          formRef={formRef}
          fields={fields}
          validations={validations}
          initialValues={initialValues}
          renderSubmit={(
            isSubmitting,
            errors: any,
            touched: any,
            setFieldTouched: any,
            handleSubmit: any
          ) => (
            <Grid item xs={12} container justify={'center'}>
              <Button
                color="primary"
                disabled={isSubmitting}
                size="large"
                type="submit"
                onClick={() => {
                  fields.forEach((field) => {
                    setFieldTouched(field.name, true);
                  });
                }}
                variant="contained">
                Sauvegarder
              </Button>
            </Grid>
          )}
          onSubmit={async (
            values: any,
            { setErrors, setStatus, setSubmitting }
          ) => {
            await setFormAction({ setErrors, setStatus, setSubmitting });

          }}
        />
      </DialogContent>
    </Dialog>
  );
}
