import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ClickAwayListener } from '@material-ui/core';
import { ChoiceOption } from './Components';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    maxWidth: 'calc(100% - 32px)',
    padding: 16,
    zIndex: 500,
    borderRadius: 10,
    backgroundColor: '#fff',
    top: 70,
    maxHeight: 'calc(100% - 80px)',
    overflow: 'auto',
    right: 64,
    [theme.breakpoints.down('xs')]: {
      right: 16,
      width: 'calc(100% - 32px)'
    }
  }
}));

interface Props {
  open: any;
  onClose: any;
}

export default function (props: Props) {
  const { open = false, onClose } = props;
  const classes = useStyles();

  return (
    open && (
      <ClickAwayListener
        onClickAway={() => {
          onClose();
        }}>
        <Box className={classes.root}>
          <ChoiceOption
            onSelectOption={() => {
              onClose();
            }}
          />
        </Box>
      </ClickAwayListener>
    )
  );
}
