import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Asset } from '../../store/types/CitiesTypes';
import { AssetDropZone, CardImage, DialogAssetConfig } from './components';
import { Box, Button, Grid } from '@material-ui/core';
import ConfirmationDialog from '../DialogConfirm';
import { useTranslation } from 'react-i18next';
import { CloudUploadOutlined } from '@material-ui/icons';
import { ButtonPrimary } from '../index';
import { useDispatch } from 'react-redux';
import { uploadAssetEffect } from '../../store/effects/AssetsEffects';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '100%',
    minHeight: 160,
    maxHeight: 160,
    borderRadius: 5,
    width: '100%'
  }
}));

interface AssetManager {
  initialState: Asset[];
  onChange: (assets: Asset[]) => void;
  provider: 'city' | 'housing';
  provider_id: number;
  sm?: boolean;
}

export interface DynamicAsset {
  url: string;
  url_mini: string;
  key: string | number;
  id?: number;
  is_panorama?: boolean;
  visible?: boolean;
  props_change?: boolean;
  isValid: boolean;
  error?: boolean;
  description_fr?: string;
  description_en?: string;
  uploading: boolean;
  created_at?: string;
  updated_at?: string;
  url_thumbnail?: string;
  city_id?: number;
  hash?: string;
  housing_id?: number;
  uploading_fail_message?: string;
  formData?: FormData;
}

export default function AssetManager(props: AssetManager) {
  const classes = useStyles();
  const [mounted, setMounted] = useState<boolean>(false);
  const { t } = useTranslation();
  const assetToDynamic: (asset: Asset) => DynamicAsset = (i) => ({
    url: i.url,
    url_mini: i.url_mini,
    url_thumbnail: i.url_thumbnail,
    city_id: i.city_id,
    housing_id: i.housing_id,
    uploading: false,
    uploading_fail_message: undefined,
    key: `${i.id}`,
    id: i.id,
    hash: i.hash,
    isValid: true,
    is_panorama: i.is_panorama,
    visible: i.visible,
    description_fr: i.description_fr,
    description_en: i.description_en,
    created_at: i.created_at,
    updated_at: i.updated_at
  });
  const [assets, setAssets] = useState<DynamicAsset[]>(
    props.initialState.map(assetToDynamic)
  );
  React.useEffect(() => {
    if (mounted) {
      // les nouveau assets
      const newAssets = props.initialState.filter(
        (asset) => assets.findIndex((i) => i.id === asset.id) === -1
      );
      //on met a jour les asset existant sans toucher les autres
      setAssets((assets) =>
        newAssets.map(assetToDynamic).concat(
          assets.map((asset) => {
            const updateAsset = props.initialState.find(
              (a) => a.id === asset.id
            );
            return updateAsset ? assetToDynamic(updateAsset) : asset;
          })
        )
      );
    } else setMounted(true);
  }, [props.initialState]);

  const [initialKey, setInitialKey] = useState<string>('0');
  const [assetToUpdateProps, setAssetToUpdateProps] = useState<
    DynamicAsset | undefined
  >();
  const [assetToDelete, setAssetToDelete] = useState<DynamicAsset | undefined>(
    undefined
  );
  const [globalLoader, setGlobalLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { provider, provider_id, sm = false } = props;

  return (
    <div>
      {!!assetToUpdateProps && (
        <DialogAssetConfig
          open={true}
          setOpen={() => setAssetToUpdateProps(undefined)}
          asset={assetToUpdateProps}
          onChange={(asset) =>
            setAssets((assets) =>
              assets.map((i) => (i.key === asset.key ? asset : i))
            )
          }
        />
      )}
      {assetToDelete && (
        <ConfirmationDialog
          title={t('dialog_confirmation')}
          text={t('asset_confirm_dialog_delete')}
          open={true}
          onYesClick={() => {
            setAssets((assets) =>
              assets.filter((i) => i.key !== assetToDelete.key)
            );
            setAssetToDelete(undefined);
          }}
          onConfirmDialogClose={() => setAssetToDelete(undefined)}
        />
      )}

      <Grid component={'div'} container spacing={2} alignItems={'stretch'}>
        <Grid
          item
          lg={sm ? 6 : 3}
          md={sm ? 6 : 4}
          sm={sm ? 12 : 6}
          xs={12}
          component={'div'}>
          <AssetDropZone
            onChange={(values) => {
              setAssets((assets) => [
                ...assets,
                ...values.map((resource, k) => ({
                  key: new Date().getTime() + k,
                  uploading: false,
                  url: resource.url,
                  // id: new Date().getTime() + k,
                  url_mini: resource.url,
                  isValid: false,
                  formData: resource.formData
                }))
              ]);
              // setInitialKey((i) => `${+i - values.length}`);
            }}
          />
        </Grid>
        {assets.map((i) => (
          <Grid
            key={i.key}
            item
            lg={sm ? 6 : 3}
            md={sm ? 6 : 4}
            sm={sm ? 12 : 6}
            xs={12}
            component={'div'}>
            <CardImage
              className={classes.img}
              onDelete={() => {
                setAssetToDelete(i);
              }}
              onUpdate={() => {
                setAssetToUpdateProps(i);
              }}
              asset={i}
            />
          </Grid>
        ))}
      </Grid>
      <Box my={1}>
        {assets.findIndex((i) => i.props_change || i.formData) !== -1 && (
          <ButtonPrimary
            onClick={() => {
              dispatch(
                uploadAssetEffect({
                  setGlobalLoader,
                  provider_id,
                  provider,
                  assets,
                  updateDynamicAsset: (asset) =>
                    setAssets((assets) =>
                      assets.map((i) => (i.key === asset.key ? asset : i))
                    )
                })
              );
            }}
            loading={globalLoader}
            variant={'contained'}
            startIcon={<CloudUploadOutlined />}
            color={'primary'}>
            {t('asset_manager_save_button')}
          </ButtonPrimary>
        )}
      </Box>
    </div>
  );
}
