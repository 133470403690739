import BaseService from './BaseService';
import { GeneralSettingsUrls } from './urls';
import { LongTitle, ShortTitle } from '../../store/types/GeneralSettingsTypes';

class GeneralSettingsService {
  static fetch_general_settings = (
    provider: string,
    page: string | number,
    per_page: string | number,
    keyword: string | number
  ) => {
    return BaseService.getRequest(
      GeneralSettingsUrls.FETCH(provider, page, per_page, keyword),
      true
    );
  };

  static add_general_setting = (
    provider: string,
    data: ShortTitle | LongTitle
  ) => {
    return BaseService.postRequest(
      GeneralSettingsUrls.ADD(provider),
      data,
      true
    );
  };

  static fetch_all_settings_data = () => {
    return BaseService.getRequest(GeneralSettingsUrls.FETCH_ALL_DATA(), false);
  };

  static update_general_setting = (
    provider: string,
    data: ShortTitle | LongTitle
  ) => {
    return BaseService.putRequest(
      GeneralSettingsUrls.UPDATE(provider),
      data,
      true
    );
  };

  static delete_general_setting = (provider: string, id: number) => {
    return BaseService.deleteRequest(
      GeneralSettingsUrls.DELETE(provider, id),
      {},
      true
    );
  };
}

export default GeneralSettingsService;
