import palette from '../palette';

export default {
  root: {
    borderRadius: '5px',
    overflow: 'hidden',
    '& fieldset': {
      // backgroundColor: '#ffffff'
    }
  },
  input: {
    '&::placeholder': {
      opacity: 1,
      color: '#818181',
      fontSize: '12px'
    }
  }
};
